import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import usericoimg from '../../../../images/usr-icon.svg';
import { Link } from 'react-router-dom';
import Api from '../../../../Api';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { get_countries } from '../../redux/master_data';
import { debounce } from 'lodash';
import { Label } from '@mui/icons-material';

const CompleteProfile1 = ({ setOpenPage }) => {
  const [isProgress, setIsProgress] = useState(false);
  const [langList, setLangList] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const getProfileData = async () => {
    const response = await Api.get('/customer/customer-details');
    setData(response.data.data);
  };

  useEffect(() => {
    getProfileData();
  }, []);
  console.log(data, 'data');
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      title: '',
      pronouns: '',
      preferrdName: '',
      email: '',
      country: '',
      city: '',
      nativeLanguage: '',
      languages: [],
      otherCities: [],
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .min(2, 'Too Short!')
        .required('Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      lastName: Yup.string()
        .trim()
        .min(2, 'Last Name must be at least 2 characters.')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      title: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      pronouns: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      preferrdName: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      email: Yup.string().email().required('Required*'),
      country: Yup.string().required('Required*'),
    }),
    onSubmit: async (values) => {
      setIsProgress(true);
      const sendData = {
        ...values,
        otherCities: values.otherCities.split(','),
      };
      const response = await Api.post('/customer/edit-profile', sendData);
      if (response.data.statusCode === 200) {
        setData(values);
        console.log('success', response.data);
        //   navigate('/completeprofile2')
        setIsProgress(false);
        setOpenPage('second');
      } else {
        console.log(response.data.errorMsg);
        setIsProgress(false);
      }
      console.log({ ...values, otherCities: values.otherCities.split(',') });
    },
  });
  useEffect(() => {
    if (data) {
      formik.setFieldValue('firstName', data.firstName);
      formik.setFieldValue('email', data.email);
      formik.setFieldValue('lastName', data.lastName);
      formik.setFieldValue('country', data.country);
      formik.setFieldValue('title', data.title);
      formik.setFieldValue('nativeLanguage', data.nativeLanguage);
      formik.setFieldValue('languages', data.languages);
      formik.setFieldValue('city', data.city);
      if (data?.otherCities?.length > 0) {
        formik.setFieldValue('otherCities', data?.otherCities?.join());
      }

    }
  }, [data]);
  const countries = useSelector((state) => {
    return get_countries(state, dispatch);
  });
  const getLanguages = async (value) => {
    const res = await Api.get('/customer/language-list?country=' + value);
    if (res?.data?.statusCode === 200) {
      setLangList(res?.data?.data);
    }
  };

  useEffect(() => {
    getLanguages(formik.values.country);
  }, [formik.values.country]);

  useEffect(() => {
    getLanguages(data.country);
  }, [data]);

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '30px',
        maxWidth: '900px',
        width: '95%',
        margin: '60px auto',
      }}
    >
      <Box p={3} sx={{ borderRadius: '10' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box
              component="img"
              sx={{
                height: 100,
                width: 100,
                maxHeight: { xs: 200, md: 250 },
                maxWidth: { xs: 200, md: 250 },
                outline: '0',
                marginBottom: '0',
              }}
              alt="UserAvatar"
              src={usericoimg}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography
              component="h1"
              variant="h4"
              gutterBottom
              fontSize="36px"
            >
              Tell us about you
            </Typography>
            <Typography variant="body2" gutterBottom color={'#5E17EB'}>
              Let’s get started. Provide a few details so we can customize your
              experience.
            </Typography>
          </Grid>
        </Grid>

        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            fullWidth
            type="text"
            id="firstName"
            autoComplete="off"
            margin="normal"
            required
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            error={
              formik.errors.firstName &&
              formik.touched.firstName &&
              formik.errors.firstName
            }
            helperText={
              formik.errors.firstName &&
              formik.touched.firstName &&
              formik.errors.firstName
            }
          />
          <TextField
            fullWidth
            type="text"
            required
            id="lastName"
            autoComplete="off"
            margin="normal"
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            error={
              formik.errors.lastName &&
              formik.touched.lastName &&
              formik.errors.lastName
            }
            helperText={
              formik.errors.lastName &&
              formik.touched.lastName &&
              formik.errors.lastName
            }
          />
          <TextField
            type="text"
            fullWidth
            id="preferrdName"
            autoComplete="off"
            margin="normal"
            label="Preferred Name (optional)"
            name="preferrdName"
            value={formik.values.preferrdName}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            error={
              formik.errors.preferrdName &&
              formik.touched.preferrdName &&
              formik.errors.preferrdName
            }
            helperText={
              formik.errors.preferrdName &&
              formik.touched.preferrdName &&
              formik.errors.preferrdName
            }
          />

          <TextField
            type="text"
            fullWidth
            id="pronouns"
            autoComplete="off"
            margin="normal"
            label="Pronouns (optional)"
            name="pronouns"
            value={formik.values.pronouns}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            error={
              formik.errors.pronouns &&
              formik.touched.pronouns &&
              formik.errors.pronouns
            }
            helperText={
              formik.errors.pronouns &&
              formik.touched.pronouns &&
              formik.errors.pronouns
            }
          />

          <TextField
            fullWidth
            id="title"
            margin="normal"
            required
            label="Title"
            name="title"
            value={formik.values.title}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            error={
              formik.errors.title && formik.touched.title && formik.errors.title
            }
            helperText={
              formik.errors.title && formik.touched.title && formik.errors.title
            }
          />
          <TextField
            fullWidth
            type="email"
            name="email"
            id="email"
            label="Email"
            margin="normal"
            required
            className="w-full"
            value={formik.values.email}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && Boolean(formik.errors.email)}
          />
          <FormControl
            fullWidth
            sx={{
              my: '16px',
            }}
          >
            <InputLabel required id="country">
              Country
            </InputLabel>
            <Select
              fullWidth
              margin="normal"
              required
              value={formik.values.country}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              id="country"
              name="country"
              label="Country"
              error={formik.touched.country && Boolean(formik.errors.country)}
            >
              {countries.map((country) => {
                return <MenuItem value={country._id}>{country.name}</MenuItem>;
              })}
            </Select>
            <FormHelperText error={true} sx={{ mt: '-8px', ml: '8px' }}>
              {formik.touched.country && formik.errors.country}
            </FormHelperText>
          </FormControl>
          {/* <FormControl
            fullWidth
            sx={{
              mt: '32px',
            }}
          >
           <InputLabel id="city">City</InputLabel>
            <Autocomplete
              options={cities}
              renderInput={(params) => <TextField {...params} label="City" />}
              onInputChange={(e) =>
                e.target.value
                  ? debouncedLoadCities(formik.values.country, e.target.value)
                  : ''
              }
              id="city"
              name="city"
              onChange={selectSearchCity}
            />
          </FormControl> */}
          <TextField
            fullWidth
            type="text"
            id="city"
            autoComplete="off"
            margin="normal"
            label="City"
            name="city"
            value={formik.values.city}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
          />

          {/* <FormControl
            fullWidth
            sx={{
              mt: '32px',
            }}
          >
            <InputLabel id="city">
              Other Cities You are interested in
            </InputLabel>
            <Autocomplete
              multiple
              options={cities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Other Cities You are interested in"
                />
              )}
              onInputChange={(e) =>
                e.target.value
                  ? debouncedLoadCities(formik.values.country, e.target.value)
                  : ''
              }
              id="otherCities"
              name="otherCities"
              onChange={selectSearchOtherCity}
            />
          </FormControl> */}
          <TextField
            fullWidth
            type="text"
            id="otherCities"
            autoComplete="off"
            margin="normal"
            label="Other Cities You are interested in"
            name="otherCities"
            value={formik.values.otherCities}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            helperText="Cities must be seperated by comma"
          />
          <FormControl
            fullWidth
            sx={{
              mt: '16px',
            }}
          >
            <InputLabel id="nativeLanguage">Native Language</InputLabel>
            <Select
              fullWidth
              margin="normal"
              value={formik.values.nativeLanguage}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              id="nativeLanguage"
              name="nativeLanguage"
              label="Native Language"
            >
              {langList.map((language) => {
                return (
                  <MenuItem value={language.langName} key={language.langName}>
                    {language.langName}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={true} sx={{ mt: '-8px', ml: '8px' }}>
              {formik.touched.nativeLanguage && formik.errors.nativeLanguage}
            </FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              mt: '32px',
            }}
          >
            <InputLabel id="languages">
              Other Languages you are interested in
            </InputLabel>
            <Select
              fullWidth
              multiple
              margin="normal"
              value={formik.values.languages}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              id="languages"
              name="languages"
              label="Other Languages you are interested in"
            >
              {langList.map((language) => {
                return (
                  <MenuItem value={language.langName} key={language.langName}>
                    {language.langName}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={true} sx={{ mt: '-8px', ml: '8px' }}>
              {formik.touched.language && formik.errors.language}
            </FormHelperText>
          </FormControl>
          <Box
            mt={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/changepassword">Change Password</Link>

            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                border: '1px solid #5e17eb',
                backgroundColor: '#5e17eb',
              }}
              disabled={isProgress}
            >
              {!isProgress && 'NEXT'}
              {isProgress && <CircularProgress />}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteProfile1;
