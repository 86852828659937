import React,{useState} from 'react'
import { Button, Box, Typography, Container, TextField, InputAdornment, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import Api from "../../../Api";
import * as Yup from "yup";
import Swal from "sweetalert2";
import {Lock} from '@mui/icons-material'

function ChangePassword() {
    const [isProgress, setIsProgress] = useState(false);

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px' }}>

            <Typography variant="h1" fontSize={"36px"} mb={5} gutterBottom fontWeight={"600"}>
                Change Password
            </Typography>
            <Formik
                initialValues={{
                    oldPassword:"",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    setIsProgress(true);
                    const response = await Api.post("/change-password", values);
                    if (response.data.statusCode === 200 ) {
                      Swal.fire({
                        title: "Success",
                        text: "Password Changed Successfully",
                        icon: "success",
                        confirmButtonText: "OK",
                      }).then(function () {
                        window.location.href = "/login";
                      });
                      resetForm()
                      setIsProgress(false);
                    } else {
                      Swal.fire({
                        title: "Error",
                        text: response.data.errorMsg,
                        icon: "error",
                        confirmButtonText: "OK",
                      });
                      setIsProgress(false);
                    }
                  }}
                  validationSchema={Yup.object().shape({
                    oldPassword: Yup.string().required("Required"),
                    newPassword: Yup.string()
                      .required("Required")
                      .min(8, "Password must be at least 8 characters.")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character."
                      ),
                    confirmPassword: Yup.string()
                      .required("Required")
                      .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
                  })}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
            

            <Box sx={{ backgroundColor: "#ffffff", marginBottom: '50px', borderRadius: '32px', padding: '50px !important' }}
                onSubmit={handleSubmit}
                component="form"
            >
                <TextField 
                type="password"
                fullWidth 
                sx={{ mb: 2 }} 
                label="Old Passward" 
                variant="outlined"
                id="oldPassword"
                value={values.oldPassword}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                required
                name="oldPassword"
                error={errors.oldPassword && touched.oldPassword}
                helperText={
                    errors.oldPassword && touched.oldPassword && errors.oldPassword
                }
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Lock />
                    </InputAdornment>
                    )
                }}

                />

                <TextField 
                    fullWidth 
                    sx={{ mb: 2 }} 
                    type="password"
                    id="newPassword"
                    value={values.newPassword}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="New Password"
                    name="newPassword"
                    error={errors.newPassword && touched.newPassword}
                    helperText={
                        errors.newPassword && touched.newPassword && errors.newPassword
                    }
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <Lock />
                        </InputAdornment>
                        )
                    }}
                 />

                <TextField 
                    fullWidth 
                    sx={{ mb: 2 }} 
                    variant="outlined" 
                    type="password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="Confirm Password"
                    name="confirmPassword"
                    error={errors.confirmPassword && touched.confirmPassword}
                    helperText={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      )
                    }}
                />
                <Box mt={3} align="center">
                    <Button 
                    type='submit'
                    variant="contained" 
                    sx={{ backgroundColor: '#5E17EB', fontSize: "18px", padding: "10px 30px" }}
                    >
                    {!isProgress && "Submit"}
                    {isProgress && <CircularProgress />}
                    </Button>
                </Box>
            </Box>
            )}
            </Formik>
        </Container>
    )
}

export default ChangePassword