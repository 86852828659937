import React, { useState} from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {Lock} from '@mui/icons-material'
import LoginLeft from "./../images/login-left.svg";
import LoginRight from "./../images/login-right.svg";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import Api from "../Api";
import * as Yup from "yup";
import Swal from "sweetalert2";

const NewPassword = () => {
  const params = useParams();
  const [isProgress, setIsProgress] = useState(false);

  return (
    <React.Fragment>
        <Container component="main" maxWidth="xl" className="login-main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h4">
              Create New Password
            </Typography>
            <Typography
              component="h4"
              sx={{
                color: "#5e17eb",
              }}
            >
              This password will be asked at the time of Sign In.
            </Typography>

            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                setIsProgress(true);
                values["confirmationCode"] = params.confirmationCode;
                const response = await Api.post("/reset-password", values);
                if (response.data.statusCode === 200 ) {
                  Swal.fire({
                    title: "Success",
                    text: "Password Reset Successfully",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then(function () {
                    window.location.href = "/login";
                  });
                  resetForm()
                  setIsProgress(false);
                } else {
                  Swal.fire({
                    title: "Error",
                    text: response.data.errorMsg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setIsProgress(false);
                }
              }}
              validationSchema={Yup.object().shape({
                newPassword: Yup.string()
                  .required("Required")
                  .min(8, "Password must be at least 8 characters.")
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character."
                  ),
                confirmPassword: Yup.string()
                  .required("Required")
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
              })}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit}
                >
                  <TextField
                    type="password"
                    id="newPassword"
                    value={values.newPassword}
                    fullWidth
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="Password"
                    name="newPassword"
                    error={errors.newPassword && touched.newPassword}
                    helperText={
                      errors.newPassword && touched.newPassword && errors.newPassword
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      )
                    }}
                  />

                  <TextField
                    type="password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    autoComplete="off"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="Confirm Password"
                    name="confirmPassword"
                    error={errors.confirmPassword && touched.confirmPassword}
                    helperText={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      )
                    }}
                  />
                  
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isProgress}
                    fullWidth
                    className="bg-theme"
                    size="large"
                    sx={{ mt: 3, backgroundColor: "#5e17eb" }}
                  >
                    {!isProgress && "Save"}
                    {isProgress && <CircularProgress />}
                  </Button>
                </Box>
              )}
            </Formik>
          </Box>
          <Box
            className="login-left"
            component="img"
            sx={{
              height: 300,
              width: 300,
              maxHeight: { xs: 300, md: 250 },
              maxWidth: { xs: 300, md: 350 },
            }}
            alt="The house from the offer."
            src={LoginLeft}
          />
          <Box
            className="login-right"
            component="img"
            sx={{
              height: 250,
              width: 350,
              maxHeight: { xs: 200, md: 250 },
              maxWidth: { xs: 300, md: 350 },
            }}
            alt="The house from the offer."
            src={LoginRight}
          />
        </Container>
    </React.Fragment>
  );
};

export default NewPassword;
