import adminConfig from './Admin/config';

// action - state management
import * as adminActionTypes from './Admin/store/actions';

const initialState = {
  country_list: [],
  city_list: [],
  lang_list: [],
  customization: {
    isOpen: [], // for active default menu
    fontFamily: adminConfig.fontFamily,
    borderRadius: adminConfig.borderRadius,
    opened: true,
  },
  showPayment: false,
};
export const reducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case adminActionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        customization: { ...initialState.customization, isOpen: [id] },
      };
    case adminActionTypes.SET_MENU:
      return {
        ...state,
        customization: { ...initialState.customization, opened: action.opened },
      };
    case adminActionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        customization: {
          ...initialState.customization,
          fontFamily: action.fontFamily,
        },
      };
    case adminActionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        customization: {
          ...initialState.customization,
          borderRadius: action.borderRadius,
        },
      };
    case 'COUNTRY_LIST':
      return Object.assign({}, state, {
        country_list: action.data,
      });
    case 'CITY_LIST':
      return Object.assign({}, state, {
        city_list: action.data,
      });
    case 'LANG_LIST':
      return Object.assign({}, state, {
        lang_list: action.data,
      });
    case 'SHOW_MODAL':
      return { showPayment: true };
    case 'HIDE_MODAL':
      return { showPayment: false };
    default:
      return state;
  }
};
