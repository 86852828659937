import { useNavigate, Outlet, Link } from "react-router-dom";
import { useEffect, useCallback } from "react";

const RequireAuth = ({ allowedRoles }) => {
    const navigate = useNavigate();

    const userType = localStorage.getItem("userType");
    const token = localStorage.getItem("token");
    const LoggedIn = token ? true : false;



    const isAllowed = allowedRoles.includes(userType);
    if (isAllowed && LoggedIn===true && token) {
        return <Outlet />;
    }
    else if (!token){
       window.location.href='/login'
    }
  
    return (
        <>{token?
            <section className="unauthorized-page" style={{display:'flex',flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                <div className="unauthorized-card">
                <h1>Unauthorized</h1>
                    <br />
                    <p>You are not authorized to view this page.</p>
                </div>
            </section>
            :''}
        </>
    );
}



export default RequireAuth;