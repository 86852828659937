import React from 'react'
import {  Box, Grid, Typography,  Card, CardContent,Button } from '@mui/material';
import NeedsIc from './../../../../images/needs-ic.svg';
import { Link } from 'react-router-dom';

const CompleteProfile3 = ({data, setOpenPage}) => {
  return (
    <Card className="book-action-inner" sx={{ backgroundColor: '#fff', padding: '30px', borderRadius: '30px', maxWidth: '900px', margin: '60px auto', textAlign: 'left', }}>
    <CardContent className="pick-date-time" >
    <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
        <Box 
            className=''
            component="img"
            sx={{
                height: 90,
                width: 90,
                maxHeight: { xs: 60, md: 90 },
                maxWidth: { xs: 60, md: 90 },
            }}
            alt="Needs icon"
            src={NeedsIc}
        />
        </Grid>
        <Grid item xs={12} md={10}>
        <Box className="needs-content">
            <Typography component="h2" variant="h5" gutterBottom >What are your current needs?</Typography>
            <Typography variant="body1" color="#3B089F">We’ve identified a set of needs that impact life at
                work. Please take a moment to complete a survey
                of needs. This process can help you identify
                and communicate your needs and allow Nomads
                grab to better support your experience. Your answers
                will not be seen by your employer.</Typography>
            <Typography variant="body1" color="#3B089F" mt={2}>The survey will take about 5-7 minutes.</Typography>
        </Box>
        </Grid>
        </Grid>
       

    </CardContent>
    <Box
                mt={4}
                display='flex'
                justifyContent='space-between'
            >
                <Button
                    onClick={() => setOpenPage('second')}
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={() => setOpenPage('forth')}
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                >
                    Next
                </Button>
            </Box>
    </Card>

  )
}

export default CompleteProfile3