import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  Stack,
  FormHelperText,
  CircularProgress
} from "@mui/material";
import React, { useState } from "react";
import {
  EmailOutlined,
  Facebook,
  Twitter,
  LinkedIn,
  Person,
  Message,
} from "@mui/icons-material";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import Api from "../Api";
import circleimg from "../images/circles-svg.svg";
import Swal from "sweetalert2";

const theme = createTheme();

const Help = () => {
  const [isProgress, setIsProgress] = useState(false);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography m={2} component="h1" variant="h4">
              Help
            </Typography>
            <Typography component="h2" variant="h6" mb={3}>
              Any question or remarks? Just write us a message!
            </Typography>
            <Box
              m={4}
              className="md-p-20"
              sx={{
                flexGrow: 1,
                maxWidth: "1000px",
                width: "95%",
                margin: "64 auto",
                padding: "50px",
                backgroundColor: "#ffffff",
                BoxShadow: "0px 0px 20px rgba(0 0 0/10%)",
                borderRadius: "20px",
              }}
            >
              <Grid
                container
                spacing={{ xs: 0, sm: 6 }}
                columns={{ xs: 6, sm: 12 }}
                sx={{ margin: "0 !important", justifyContent: "space-between" }}
              >
                <Grid
                  item
                  md={12}
                  xs={6}
                  sx={{
                    bgcolor: "#5E17EB",
                    padding: "35px",
                    borderRadius: "27px",
                    position: "relative",
                    maxWidth: "xs",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    mb={2}
                    textAlign="left"
                    sx={{ color: "#ffffff", fontFamily: "Poppins" }}
                  >
                    Contact Information
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    mb={4}
                    textAlign="left"
                    sx={{ color: "#ffffff", fontFamily: "Poppins" }}
                  >
                    Fill up the form and our team will get back to you within 24
                    hours.
                  </Typography>
                  
                  <Stack direction="row" spacing={2} mb={4}>
                    <EmailOutlined style={{ color: "#ffffff" }} />
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <a style={{ color:'white', textDecoration:'none'}} href="mailto:info@nomadgrab.com">info@nomadgrab.com</a>
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Link href="https://www.facebook.com/">
                    <Facebook
                      sx={{
                        color: "#ffffff",
                        padding: "5px",
                        borderRadius: "50%",
                        border: "1px solid #ffffff",
                      }}
                    />
                    </Link>
                    <Link href="https://twitter.com">
                    <Twitter
                      sx={{
                        color: "#ffffff",
                        padding: "5px",
                        borderRadius: "50%",
                        border: "1px solid #ffffff",
                      }}
                    />
                    </Link>
                    <Link href="https://www.linkedin.com/login">
                    <LinkedIn
                      sx={{
                        color: "#ffffff",
                        padding: "5px",
                        borderRadius: "50%",
                        border: "1px solid #ffffff",
                      }}
                    />
                    </Link>
                  </Stack>

                  <Box
                    component="img"
                    sx={{
                      height: 300,
                      width: 300,
                      maxHeight: { xs: 140, md: 250 },
                      maxWidth: { xs: 124, md: 250 },
                      position: 'absolute', bottom: '-15px', right: '0', transform: 'none', borderRadius: '0', outline: '0', marginBottom: '0',
                  
                    }}
                    alt="circleAvatar"
                    className="circle-help"
                    src={circleimg}
                  />
                </Grid>
                <Grid
                  className="contact-input"
                  item
                  md={12}
                  xs={6}
                  sx={{ padding: "48px" }}
                >
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      message: "",
                    }}
                    onSubmit={async (values, { resetForm }) => {
                      setIsProgress(true);
                      const response = await Api.post("/contact-us", values);
                      if (response.data.statusCode === 200) {
                          Swal.fire({
                            title: "Success",
                            text: "Message Sent Successfully",
                            icon: "success",
                            confirmButtonText: "OK",
                          });

                          resetForm({
                            firstName: "",
                            lastName: "",
                            email: "",
                            message: "",
                          });
                          setIsProgress(false);
                      } else {
                        Swal.fire({
                          title: "Error",
                          text: response.data.errorMsg,
                          icon: "error",
                          confirmButtonText: "OK",
                        });
                        setIsProgress(false);
                      }
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .trim()
                        .min(2, "Too Short!")
                        .required("Required")
                        .matches(
                          /^[aA-zZ\s]+$/,
                          "Only alphabets are allowed for this field "
                        ),
                      lastName: Yup.string().trim().min(2, "Last Name must be at least 2 characters.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Required"),
                      email: Yup.string()
                        .email("Invalid email.")
                        .required("Required"),
                      message: Yup.string()
                        .trim()
                        .min(4, "Too Short!")
                        .max(300, "Max 300 words allowed!")
                        .required("Required"),
                    })}
                  >
                    {({
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      touched,
                    }) => (
                      <Box
                        component="form"
                        noValidate
                        sx={{ mt: 1, fontFamily: "Poppins" }}
                        onSubmit={handleSubmit}
                      >
                        <TextField
                          fullWidth
                          type="text"
                          id="firstName"
                          value={values.firstName}
                          autoComplete="off"
                          margin="normal"
                          required
                          label="First Name"
                          name="firstName"
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={errors.firstName && touched.firstName}
                          helperText={
                            errors.firstName &&
                            touched.firstName &&
                            errors.firstName
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          type="text"
                          fullWidth
                          required
                          id="lastName"
                          value={values.lastName}
                          autoComplete="off"
                          margin="normal"
                          label="Last Name"
                          name="lastName"
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={
                            errors.lastName &&
                            touched.lastName &&
                            errors.lastName
                          }
                          helperText={
                            errors.lastName &&
                            touched.lastName &&
                            errors.lastName
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          type="text"
                          id="email"
                          value={values.email}
                          fullWidth
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          margin="normal"
                          required
                          label="Email Address"
                          name="email"
                          error={errors.email && touched.email}
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlined />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          type="text"
                          variant="outlined"
                          margin="normal"
                          maxlength="300"
                          multiline
                          rows={4}
                          required
                          fullWidth
                          id="message"
                          label="Message"
                          name="message"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          error={errors.message && touched.message}
                          helperText={`${values.message.length}/${"300"}`}
                          InputProps={{
                            maxlength: 300,
                            startAdornment: (
                              <InputAdornment
                                sx={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                }}
                                position="start"
                              >
                                <Message />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage
                          name="message"
                          render={(msg) => (
                            <FormHelperText
                              error={errors.message && touched.message}
                            >
                              {msg}
                            </FormHelperText>
                          )}
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={handleSubmit}
                          disabled={isProgress}
                          size="large"
                          sx={{ mt: 3, mb: 5, backgroundColor: "#5e17eb" }}
                        >
                          {!isProgress && "Send message"}
                          {isProgress && <CircularProgress />}
                        </Button>
                      </Box>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Help;
