import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Api from '../Api';
import CircularProgress from '@mui/material/CircularProgress';
import {Alert, Box, Link, Typography} from "@mui/material";
import Swal from 'sweetalert2'

const Verify = (props) => {
    const params = useParams();
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')

    const handleVerify = async (confirmationCode) => {
        setLoading(true)
        const response = await Api.post('/verify', {confirmationCode: confirmationCode})
        if (response.data.statusCode === 200) {
            Swal.fire({
                title: "Success",
                text: "Email verified. Please login.",
                icon: "success",
                confirmButtonText: "OK",
              }).then(function () {
                  // Redirect the user
                  window.location.href = "/login";
                });
            setSuccess("Email verified. Please login.");
            setLoading(false)
        } else {
            Swal.fire({
                title: "Error",
                text: response.data.errorMsg,
                icon: "error",
                confirmButtonText: "OK",
              }).then(function () {
                  // Redirect the user
                  window.location.href = "/login";
                });
            setError(response.data.errorMsg)
            setLoading(false)
        }
    }
   
    useEffect(() => {
        console.log("params", params)
        handleVerify(params.confirmationCode);
    }, []);

    return (
        <>

            {loading ? (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "30vh"}}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "70vh", }}
                >
                    {/* {error ? (
                        <Box sx={{boxShadow: '0px 0px 20px rgba(0 0 0/ 10%)', padding: '50px', backgroundColor: '#ffffff', borderRadius: '10px',  }}>
                             <Typography mb={3} sx={{ color: '#d72e2e', fontWeight:'600', textTransform: 'capitalize',fontSize: '22px',fontFamily: 'poppins'}}>Registration failed!</Typography>
                            
                            <Alert mt={2} sx={{justifyContent:'center'}} severity="error">{error}</Alert>
                            <Typography mt={1}>Please try again</Typography>
                            <Link  href="/login" >Login</Link>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {success ? (
                        <Box sx={{lineHeight:'3',boxShadow: '0px 0px 20px rgba(0 0 0/ 10%)', padding: '50px', marginTop: '20px', backgroundColor: '#ffffff', borderRadius: '10px', }}>
                            <Typography mb={3} sx={{ color: '#41ad1c', fontWeight:'600', textTransform: 'capitalize',fontSize: '22px',fontFamily: 'poppins'}}>Registration successful. Please login.</Typography>
                        <Alert severity="success" sx={{justifyContent:'center', marginTop:'20px'}}>{success}</Alert>
                        <Typography mt={1}>Please login to continue</Typography>
                        <Link href="/login" >Login</Link>
                        </Box>
                    ) : (
                        <></>
                    )} */}
                </Box>
            )}
        </>
    )
}

export default Verify