import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from '../../../config';
// import Logo from '../../../ui-component/Logo';
import Logo from '../../../../images/logo.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        {/* <Logo /> */}
        <img src={Logo} />
    </ButtonBase>
);

export default LogoSection;
