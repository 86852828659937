import Api from '../../../Api';

export const get_countries = (state, dispatch ) => {
    if(state.country_list && state.country_list.length){
      return state.country_list;
    }
    else{
        fetch_countries(dispatch);
        return [];
    }
}

const fetch_countries = async (dispatch) => {
    const res = await Api.get('/countries');
    if(res.data && res.data.data && res.data.data.length > 0){
      dispatch({type:'COUNTRY_LIST', data: res.data.data});
      return res.data.data;
    }
}

export const get_lang = (state, dispatch ) => {
  if(state.lang_list && state.lang_list.length){
    return state.lang_list;
  }
  else{
      fetch_lang(dispatch);
      return [];
  }
}

const fetch_lang = async (dispatch) => {
  const res = await Api.get('/customer/language-list');
  if(res.data && res.data.data && res.data.data.length > 0){
    dispatch({type:'LANG_LIST', data: res.data.data});
    return res.data.data;
  }
}

