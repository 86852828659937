import React, { useEffect, useState } from 'react'
import {
    TablePagination,
    Box,
    Typography,
    Container, Tab, Button
} from "@mui/material";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Api from "../../../Api";
import moment, { utc } from 'moment';
import Swal from 'sweetalert2';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F1F3F6',
        color: ' #000000',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const UpcomingSession = () => {
    const [value, setValue] = useState('1');
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('');
    const [session, setSession] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        getSessionList(newValue == 2 ? "upcomingCancelled" : newValue == 3 ? "previous" :newValue == 4? "previousCancelled" : "scheduled",1);
    };

    const getSessionList = async (status, page = 1) => {
        setPage(page);
        setStatus(status);
        const response = await Api.get("/consultant/session-list?orderDirection=asc&orderBy=dateTime&page=" + page + "&status=" + status +"&date=" + new Date());
        console.log(response.data.data.docs,'llllll')
        setSession([...response.data.data.docs]);
        setTotalCount(response.data.data.total);
    }

    const handleChangePage = (event = null, newPage = 1) => {
        getSessionList(status, newPage + 1);
    };

    const timeDifference = (time) => {
        return moment(time).diff(moment(),'minutes');
    }

    const checkDeleteButton = (item) => {
        const join_start_time = moment().add(+240, 'minutes').toDate();
        console.log(join_start_time,'join_start_time')
    
        let show_btn = true;
        if(item.status !== "scheduled"){
          show_btn = false;
        }
        else if(new Date(item.dateTime) < join_start_time){
          show_btn = false;
        }
    
        return show_btn;
    }

    const checkJoinButton = (item) => {
        const join_start_time = moment().add(+5, 'minutes').toDate();
        const join_end_time = moment().add(-60, 'minutes').toDate();
    
        let show_btn = false;
        if(new Date(item.dateTime) < join_start_time && new Date(item.dateTime) > join_end_time){
          show_btn = true;
        }
    
        return show_btn;
    }

    const cancelSession = async(item) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, cancel it!",
          cancelButtonText: "No, don't cancel !",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            Swal.fire(
              "Cancelled!",
              "Your session has been cancelled.",
              "success"
            );
            await Api.post("/consultant/cancel-session", { _id: item._id });
            getSessionList(status, page);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancel Revoked", "Session not cancelled", "error");
          }
        });
    }
    useEffect(() => {
        getSessionList('scheduled', 1);
    }, []);
    return (
        <>
            <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px' }}>
                <Box mb={5}>
                    <Typography variant="h1" gutterBottom fontSize={"40px"}>Upcoming/Previous sessions</Typography>
                    <Typography variant="body2" gutterBottom >Your current Time Zone is {Intl.DateTimeFormat().resolvedOptions().timeZone} UTC {moment(new Date()).format("Z")}</Typography>
                </Box>
                <Box className='p-20' sx={{ backgroundColor: "#ffffff", marginBottom: '50px', borderRadius: '32px', padding: '50px' }}>
                    <Box className="consultab" sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box TabIndicatorProps={{ sx: { display: 'none' } }}
                                sx={{
                                    '& .MuiTabs-flexContainer': {flexWrap: 'wrap',},
                                }}>
                                <TabList onChange={handleChangeTab} aria-label="lab API tabs example" textColor="secondary" indicatorColor="#5E17EB" >
                                    <Tab label="Upcomming" value="1" />
                                    <Tab label="Upcomming cancelled" value="2" />
                                    <Tab label="Previous" value="3" />
                                    <Tab label="Previous cancelled" value="4" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{padding :"10px 0 0 0 "}}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead fullwidth>
                                            <TableRow>
                                                <StyledTableCell>Start Time</StyledTableCell>
                                                <StyledTableCell align="left">Customer Name</StyledTableCell>
                                                <StyledTableCell align="left">Language</StyledTableCell>
                                                <StyledTableCell align="left">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {session.length>0?session.map((row) => (
                                                <TableRow key={row.stime}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {moment(row.dateTime).format("ddd, MMM D")}<br />
                                                        {moment(row.dateTime).format("hh:mm A")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.customerName}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.language}</StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {checkJoinButton(row) &&
                                                            <Button
                                                            variant="contained"
                                                            size="small"
                                                            sx={{
                                                                border: '1px solid #5e17eb',
                                                                backgroundColor: '#5e17eb',
                                                            }}
                                                            href={row.consultantZoomLink}
                                                            target="_blank"
                                                            >
                                                            Join
                                                            </Button>
                                                        }
                                                        {checkDeleteButton(row) &&
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                size="small"
                                                                onClick={() => cancelSession(row)}
                                                                >
                                                                Cancel
                                                            </Button>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            )):<Typography m={2} >Nothing to show</Typography>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={10}
                                    page={page - 1}
                                    onPageChange={handleChangePage} />
                            </TabPanel>
                            <TabPanel value="2" sx={{padding :"10px 0 0 0 "}}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead fullwidth>
                                            <TableRow>
                                                <StyledTableCell>Start Time</StyledTableCell>
                                                <StyledTableCell align="left">Customer Name</StyledTableCell>
                                                <StyledTableCell align="left">Language</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {session.length>0?session.map((row) => (
                                                <TableRow key={row.stime}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {moment(row.dateTime).format("ddd, MMM D")}<br />
                                                        {moment(row.dateTime).format("hh:mm A")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.customerName}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.language}</StyledTableCell>
                                                </TableRow>
                                            )):<Typography m={2} >Nothing to show</Typography>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={10}
                                    page={page - 1}
                                    onPageChange={handleChangePage} />
                            </TabPanel>
                            <TabPanel value="3" sx={{padding :"10px 0 0 0 "}}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead fullwidth>
                                            <TableRow>
                                                <StyledTableCell>Start Time</StyledTableCell>
                                                <StyledTableCell align="left">Customer Name</StyledTableCell>
                                                <StyledTableCell align="left">Language</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {session.length>0?session.map((row) => (
                                                <TableRow key={row.stime}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {moment(row.dateTime).format("ddd, MMM D")}<br />
                                                        {moment(row.dateTime).format("hh:mm A")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.customerName}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.language}</StyledTableCell>
                                                </TableRow>
                                            )):<Typography m={2} >Nothing to show</Typography>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={10}
                                    page={page - 1}
                                    onPageChange={handleChangePage} />
                            </TabPanel>
                            <TabPanel value="4" sx={{padding :"10px 0 0 0 "}}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead fullwidth>
                                            <TableRow>
                                                <StyledTableCell>Start Time</StyledTableCell>
                                                <StyledTableCell align="left">Customer Name</StyledTableCell>
                                                <StyledTableCell align="left">Language</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {session.length > 0 ? session.map((row) => (
                                                <TableRow key={row.stime}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {moment(row.dateTime).format("ddd, MMM D")}<br />
                                                        {moment(row.dateTime).format("hh:mm A")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.customerName}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.language}</StyledTableCell>
                                                </TableRow>
                                            )):<Typography m={2} >Nothing to show</Typography>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={10}
                                    page={page - 1}
                                    onPageChange={handleChangePage} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default UpcomingSession