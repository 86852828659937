import React, { useRef, useState, useEffect } from "react";
import { Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, InputAdornment, Alert, CircularProgress } from '@mui/material';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { EmailOutlined} from '@mui/icons-material';
import axios from 'axios';
import LoginLeft from './../images/login-left.svg'
import LoginRight from './../images/login-right.svg'
import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import Api from "../Api";

const theme = createTheme();
const ForgotPassword = () => {
    const navigate = useNavigate();

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isProgress, setIsProgress] = useState(false);


    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xl" className='login-main'>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h4" sx={{ mb: 2}}>
                            Forgot Password ?
                        </Typography>
                        <Typography component="h6" sx={{
                                color: '#5e17eb', textAlign:'center'}}>
                            Enter your email address and we will send you a link<br/> to reset your password.
                        </Typography>
                        

                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                onSubmit={async(values, { resetForm }) => {
                                    setIsProgress(true);
                                    setSuccess("");
                                    setError("");
                                    console.log(values);
                                    const response = await Api.post("/forgot-password", values);
                                    if  (response.data.statusCode === 200 ) {
                                   
                                    Swal.fire({
                                        title: "Success",
                                        text: "We have sent you an email with a link to reset your password.",
                                        icon: "success",
                                        confirmButtonText: "OK",
                                      }).then(function () {
                                          // Redirect the user
                                          window.location.href = "/login";
                                        });
                                        resetForm();
                                        setIsProgress(false);
                                    }
                                    else {
                                        Swal.fire({
                                            title: "Error",
                                            text: response.data.errorMsg,
                                            icon: "error",
                                            confirmButtonText: "OK",
                                          }).then(function () {
                                              // Redirect the user
                                              window.location.href = "/login";
                                            });
                                            setIsProgress(false);
                                    }
                                }
                                }
                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                        .email('Email is not valid')
                                        .required('Email is required'),
                                })}
                            >
                                {({ isSubmitting,  errors, handleChange, handleBlur, handleSubmit, values, touched, resetForm }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field 
                                        id="email"
                                        name="email" 
                                        type="email" 
                                        as={TextField}
                                        label="Email"
                                        variant="outlined"
                                        margin="normal" 
                                        fullWidth 
                                        autoComplete="email" 
                                        value={values.email} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                        error={errors.email && touched.email} 
                                        helperText={errors.email && touched.email ? errors.email : null} 
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailOutlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                        />

                                        <Field 
                                        as={Button} 
                                        type="submit" 
                                        variant="contained" 
                                        color="primary" 
                                        disabled={isSubmitting}
                                        fullWidth
                                        className="bg-theme"
                                        size="large"
                                        sx={{ mt: 3, backgroundColor: "#5e17eb" }}
                                        >
                                            {!isProgress && "Send Reset Link"}
                                            {isProgress && <CircularProgress />}
                                            
                                        </Field>
                                    </form>
                                )}
                            </Formik>
                    </Box>
                    <Box
                        className='login-left'
                        component="img"
                        sx={{
                            height: 300,
                            width: 300,
                            maxHeight: { xs: 300, md: 250 },
                            maxWidth: { xs: 300, md: 350 },
                        }}
                        alt="The house from the offer."
                        src={LoginLeft}
                    />
                    <Box
                        className='login-right'
                        component="img"
                        sx={{
                            height: 250,
                            width: 350,
                            maxHeight: { xs: 200, md: 250 },
                            maxWidth: { xs: 300, md: 350 },
                        }}
                        alt="The house from the offer."
                        src={LoginRight}
                    />
                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default ForgotPassword