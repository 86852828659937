import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import DemoHome from './Front/Customer/Views/DemoHome';
import './App.css';

/*Public*/
// import Navbar from './layout/Navbar';
import Header from './layout/Header';
import Login from './layout/Login';
import Register from './layout/Register';
import Footer from './layout/Footer';
import RequireAuth from './layout/RequireAuth';
import Verify from './services/Verify';
import ForgotPassword from './layout/ForgotPassword';
import NewPassword from './layout/NewPassword';
import Help from './layout/Help';
import RequestDemo from './layout/RequestDemo';
/*/Public*/

/*Customer*/
import Home from './Front/Customer/Views/Home';
import BookSession from './Front/Customer/Views/BookSession';
import Calendar from './layout/Calendar';
import CompleteProfile1 from './Front/Customer/Views/CompleteProfile/CompleteProfile1';
import CompleteProfile2 from './Front/Customer/Views/CompleteProfile/CompleteProfile2';
import CompleteProfile3 from './Front/Customer/Views/CompleteProfile/CompleteProfile3';
import CompleteProfile4 from './Front/Customer/Views/CompleteProfile/CompleteProfile4';
import Profile from './Front/Customer/Views/CompleteProfile/Profile';
import Feedback from './Front/Customer/Views/Feedback/Feedback';
import SessionsStatus from './Front/Customer/Views/SessionStatus';
import Report from './Front/Customer/Views/Report';
import ChangePassword from './Front/Customer/Views/ChangePassword';
/*/Customer*/

/*Consultant*/
import AvailableSlots from './Front/Consultant/Views/AvailableSlots';
import Consultant from './Front/Consultant/Views/Consultant';
import UpdateProfileCons from './Front/Consultant/Views/UpdateProfileCons';
import UpcomingSession from './Front/Consultant/Views/UpcomingSessions';
import UserFeedback from './Front/Consultant/Views/UserFeedback';
import SessionList from './Front/Customer/Views/Feedback/SessionList';
/*/Consultant*/

const userType = {
  Customer: 'customer',
  Consultant: 'consultant',
  Admin: 'admin',
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const firebaseConfig = {
    apiKey: 'AIzaSyD05iNWVTcGHG8DvcuhmBE6dVgxbxrEPjc',
    authDomain: 'nomad-2cc6d.firebaseapp.com',
    projectId: 'nomad-2cc6d',
    storageBucket: 'nomad-2cc6d.appspot.com',
    messagingSenderId: '56204077797',
    appId: '1:56204077797:web:df6e3e5653d8179391420c',
    measurementId: 'G-0D2WMJQVH1',
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, []);

  return (
    <>
      {
        location.pathname !== '/requestdemo' && <Header />
      }
      {/* {location.pathname!=='/requestdemo' && <Navbar />} */}
      <Routes>
        {/* public routes */}
        <Route path="/verify-user/:confirmationCode" element={<Verify />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/help" element={<Help />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/reset-password/:confirmationCode"
          element={<NewPassword />}
        />
        <Route path="calendar" element={<Calendar />} />
        <Route path="requestdemo" element={<RequestDemo />} />

        {/*protected routes */}
        <Route element={<RequireAuth allowedRoles={[userType.Customer]} />}>
          <Route path="/booksession" element={<BookSession />} />
          <Route path="/home-old" element={<Home />} />
          <Route path="/home" element={<DemoHome />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/completeprofile1" element={<CompleteProfile1 />} />
          <Route path="/completeprofile2" element={<CompleteProfile2 />} />
          <Route path="/completeprofile3" element={<CompleteProfile3 />} />
          <Route path="/completeprofile4" element={<CompleteProfile4 />} />
          <Route path="/sessionstatus" element={<SessionsStatus />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/sessionlist" element={<SessionList />} />
          <Route path="/report" element={<Report />} />
          <Route path="/changepassword" element={<ChangePassword />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[userType.Consultant]} />}>
          <Route path="/consultant/home" element={<Consultant />} />
          <Route
            path="/consultant/availableslots"
            element={<AvailableSlots />}
          />
          <Route
            path="/consultant/updateprofilecons"
            element={<UpdateProfileCons />}
          />
          <Route
            path="/consultant/upcomingsessions"
            element={<UpcomingSession />}
          />
          <Route path="/consultant/userfeedback" element={<UserFeedback />} />
          <Route
            path="/consultant/changepassword"
            element={<ChangePassword />}
          />
        </Route>
      </Routes>
      {location.pathname !== '/requestdemo' && <Footer />}
      {/* location.pathname !== '/demo-home' && <Footer />} */}
    </>
  );
}

export default App;
