import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import Payment from '../Payments';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../../Api';

const PaymentModal = ({ bookingVerify, fromBooking = false }) => {
  const showPaymentModal = useSelector((state) => state.showPayment);
  // const [subscriptionData, setSubscriptionData] = useState([]);
  const dispatch = useDispatch();
  // const subscriptionStatus = async () => {
  //   const response = await Api.get('/customer/subscription');
  //   setSubscriptionData(response?.data?.subscription);
  // };

  // useEffect(() => {
  //   subscriptionStatus();
  // }, []);
  const handlePaymentModalClose = () => {
    if (fromBooking) {
      bookingVerify();
    }
    dispatch({ type: 'HIDE_MODAL' });
  };
  const handlePaymentModalOpen = () => {
    dispatch({ type: 'SHOW_MODAL' });
  };
  return (
    <Modal
      open={showPaymentModal}
      onClose={handlePaymentModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // hideBackdrop={true}
    >
      <Payment onClose={handlePaymentModalClose} />
    </Modal>
  );
};

export default PaymentModal;
