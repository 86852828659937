import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  CircularProgress,
  Link,
  Card,
  CardMedia,
  CardContent,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  NativeSelect,
} from "@mui/material";
import { red } from "@mui/material/colors";
import {
  EmailOutlined,
  Person,
  Message,
  TaskAlt,
  Public,
} from "@mui/icons-material";
import { ErrorMessage, Field, Formik } from "formik";
import Swal from "sweetalert2";
import Api from "../Api";
import { useNavigate } from "react-router-dom";
import greenTick from "../images/greenTick.svg";
import booking from "../images/booking.svg";
import dataDog from "../images/dataDog.svg";
import google from "../images/google.svg";
import microSoft from "../images/microsoft.svg";
import LoginLogo from "../images/logo.svg";
import QuoteIc from "../images/inverted-comas.svg";
import { useDispatch, useSelector } from "react-redux";
import { get_countries } from "../Front/Customer/redux/master_data";

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  country: "",
  description: "",
};

const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(2, "First Name must be at least 2 characters.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .min(2, "Last Name must be at least 2 characters.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .required("Required"),
  email: Yup.string().email("Invalid email.").required("Required"),
  company: Yup.string().required("Required"),
  country: Yup.string().required("Please, select country"),
  description: Yup.string().required("Required"),
});

const RequestDemo = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isProgress, setIsProgress] = useState(false);
  const homeNavigate = useNavigate();
  const dispatch = useDispatch();

  const countries = useSelector((state) => {
    return get_countries(state, dispatch);
  });

  useEffect(() => {
    let tokenLocal = localStorage.getItem("token");
    if (tokenLocal) {
      homeNavigate("/home");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="request-demo-wrapper">
        <Container
          maxWidth="xl"
          className="login-main book-your-demo login-main-wrapper"
          style={{
            backgroundColor: "#e8e6ff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CssBaseline />
          <Box
            m={4}
            sx={{
              margin: "64 auto",
              backgroundColor: "#e8e6ff",
              marginTop: 3,
              marginLeft: 6,
              padding: "0 5%",
            }}
          >
            <Link href="https://nomadgrab.com/#">
              <Box
                className="login-logo"
                component="img"
                sx={{
                  width: 300,
                  maxHeight: { xs: 150, md: 150 },
                  maxWidth: { xs: 150, md: 150 },
                }}
                alt="Logo"
                src={LoginLogo}
              />
            </Link>
            <Typography
              variant="h2"
              fontWeight={450}
              sx={{ paddingRight: "40%" }}
            >
              Never Feel Left Behind Again With Nomad Grab
            </Typography>
            <Typography variant="h4" mt={2} fontWeight={450}>
              What is Nomad Grab?
            </Typography>
            <Typography variant="h6" mt={2} fontWeight={300}>
              Nomad Grab provides individualized coaching to support nomads in
              the moments they need it most.
            </Typography>
            <Typography variant="h6" mt={3} mb={2} fontWeight={700}>
              Improve Your Traveling Experience With Nomad Grab By:
            </Typography>
            <Card sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ maxWidth: "27px " }}
                  height={"27px"}
                  alt="Tick"
                  src={greenTick}
                />
                <CardContent sx={{ padding: "0 !important" }}>
                  <Typography
                    sx={{ paddingLeft: "10px" }}
                    variant="p"
                    fontWeight={300}
                  >
                    Engaging with a world-wide group of nomads
                  </Typography>
                </CardContent>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ maxWidth: "27px " }}
                  height={"27px"}
                  alt="Tick"
                  src={greenTick}
                />
                <CardContent sx={{ padding: "0 !important" }}>
                  <Typography
                    sx={{ paddingLeft: "10px" }}
                    variant="p"
                    fontWeight={300}
                  >
                    Advancing your career as a remote worker
                  </Typography>
                </CardContent>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ maxWidth: "27px " }}
                  height={"27px"}
                  alt="Tick"
                  src={greenTick}
                />
                <CardContent sx={{ padding: "0 !important" }}>
                  <Typography
                    sx={{ paddingLeft: "10px" }}
                    variant="p"
                    fontWeight={300}
                  >
                    Finding fun in any city
                  </Typography>
                </CardContent>
              </Box>
            </Card>
            <Typography variant="h6" mt={2} fontWeight={500}>
              Our coaches work at companies such as:
            </Typography>
            <Box className="our-coaches" sx={{ display: "flex" }} mt={2} mb={4}>
              <Box component="img" src={microSoft} alt="microsoft" />
              <Box component="img" ml={2} src={google} alt="google" />
              <Box component="img" ml={2} src={booking} alt="booking.com" />
              <Box component="img" ml={2} src={dataDog} alt="dataDog" />
            </Box>
          </Box>
          <Box
            sx={{
              // margin: 3,
              marginRight: "100%",
              padding: "43px 62px 43px 43px",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: 10,
              width: "55%",
            }}
          >
            <Typography mt={2} component="h1" variant="h4" align="center">
              Book Your Demo{" "}
              <Typography variant="h6">
                (or,{" "}
                <Link href="/login" color={red[500]}>
                  log in
                </Link>{" "}
                here)
              </Typography>
            </Typography>

            <Formik
              initialValues={INITIAL_FORM_STATE}
              onSubmit={async (values, { resetForm }) => {
                setIsProgress(true);
                setSuccess("");
                setError("");
                const response = await Api.post("/book-demo-session", values);
                if (response.data.statusCode === 200) {
                  Swal.fire({
                    title: "Success",
                    text: "Demo Session Booked Successfully",
                    icon: "success",
                    confirmButtonText: "OK",
                  });
                  resetForm(INITIAL_FORM_STATE);
                  setIsProgress(false);
                } else {
                  Swal.fire({
                    title: "Error",
                    text: response.data.errorMsg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setIsProgress(false);
                }
              }}
              validationSchema={FORM_VALIDATION_SCHEMA}
            >
              {(props) => {
                const {
                  errors,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  values,
                  touched,
                } = props;
                return (
                  <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      type="text"
                      id="firstName"
                      value={values.firstName}
                      autoComplete="off"
                      margin="normal"
                      required
                      label="First Name"
                      name="firstName"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      error={errors.firstName && touched.firstName}
                      helperText={
                        errors.firstName &&
                        touched.firstName &&
                        errors.firstName
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="text"
                      fullWidth
                      id="lastName"
                      required
                      value={values.lastName}
                      autoComplete="off"
                      margin="normal"
                      label="Last Name"
                      name="lastName"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      error={
                        errors.lastName && touched.lastName && errors.lastName
                      }
                      helperText={
                        errors.lastName && touched.lastName && errors.lastName
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="text"
                      id="email"
                      value={values.email}
                      fullWidth
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      required
                      label="Email Address"
                      name="email"
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="text"
                      id="company"
                      value={values.company}
                      fullWidth
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      required
                      label="Job Title"
                      name="company"
                      error={errors.company && touched.company}
                      helperText={
                        errors.company && touched.company && errors.company
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControl fullWidth sx={{ mt: "16px", pb: "px" }}>
                      <InputLabel
                        id="country"
                        required
                        error={errors.country && touched.country}
                      >
                        Country
                      </InputLabel>
                      <Field
                        as={NativeSelect}
                        input={<OutlinedInput label="Country" name="country" />}
                        required
                        variant="outlined"
                        label="Country"
                        id="country"
                        value={values.country}
                        name="country"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("country", e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.country && touched.country}
                        helperText={touched.country && errors.country}
                        startAdornment={
                          <InputAdornment position="start">
                            <Public />
                          </InputAdornment>
                        }
                      >
                        {countries.map((country) => {
                          return (
                            <option
                              component="li"
                              value={country._id}
                              key={country._id}
                            >
                              {country.name}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="country"
                        render={(msg) => (
                          <FormHelperText
                            error={errors.country && touched.country}
                          >
                            {msg}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>

                    <InputLabel
                      sx={{
                        wordWrap: "break-word",
                        marginTop: "15px",
                        whiteSpace: "normal",
                      }}
                    >
                      Why are you interested in working with Nomad Grab?
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      margin="normal"
                      multiline
                      rows={4}
                      required
                      fullWidth
                      id="description"
                      name="description"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={errors.description && touched.description}
                      helperText={
                        errors.description && touched.description
                          ? errors.description
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              display: "flex",
                              flexDirection: "column-reverse",
                            }}
                            position="start"
                          >
                            <Message />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      onClick={handleSubmit}
                      disabled={isProgress}
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="bg-theme"
                      size="large"
                      sx={{
                        mt: 3,
                        backgroundColor: "#5e17eb",
                        borderRadius: "40px",
                      }}
                    >
                      {!isProgress && "Send"}
                      {isProgress && <CircularProgress />}
                    </Button>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </div>
      <Box className="login-new-footer">
        <Container maxWidth="xl">
          <div className="footer-left">
            <Box
              className="footer-quote-ic"
              component="img"
              sx={{
                height: 100,
                width: 100,
                maxHeight: { xs: 60, md: 80 },
                maxWidth: { xs: 60, md: 80 },
              }}
              alt="quote"
              src={QuoteIc}
            />
            <h2>About Nomad Grab</h2>
            <p>
              Nomad Grab connects nomads to on-demand professional coaching in
              the key moments that define the nomadic experience, making it
              possible for nomads to engage in a community that supports and
              uplifts them.
            </p>
          </div>
          <div className="footer-right">
            <h2>Reach Out To Us</h2>
            <a href="mailto:hello@nomadgrab.com">hello@nomadgrab.com</a>
          </div>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default RequestDemo;
