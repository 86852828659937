import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Container,
  Button,
  Box,
  Rating,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  FormLabel,
  ListItemText,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {styled } from "@mui/material/styles";
import { Formik, ErrorMessage } from "formik";
import Api from "../../../../Api";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#5E17EB",
  },
  "& .MuiRating-iconHover": {
    color: "#6C63FF",
  },
});

const Feedback = (data) => {
  const location = useLocation();
  const session = useMemo(() => location.state);
  const [isProgress, setIsProgress] = useState(false);
  const [dname, setDname] = useState([]);
  const [consultName, setConsultName] = useState()
  const [consultDate, setConsultDate] = useState()
   console.log('ll', session._id)
  const names = [
    {"value": "stress", "label": "Stress"},
    {"value": "moving_to_new_city", "label": "Moving to a new city"},
    {"value":"working_relationships", "label": "Working relationships"},
    {"value":"looking_activities","label":"Looking for activities"},
    {"value":"growth","label":"Growth at work"},
    {"value":"making_friends","label":"Making friends"},
    {"value":"something_else","label":"Something else"}
  ];

  const renderValue = (selected) => {
    let selectedNames = [];
    names.map(name => {
      if(selected.indexOf(name.value) != -1){
        selectedNames.push(name.label);
      } 
    });
    
    return selectedNames.join(", ");     
  }
  const consultantName=async()=>{
    const response = await Api.get('/customer/session_details?sessionId='+session._id);
    console.log(response.data.data.consultantName,'response')
    setConsultName(response.data.data.consultantName)
    setConsultDate(response.data.data.sessionDate)
    return response.data.data.consultantName && response.data.data.sessionDate
  }

  const openSelect = async() => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  }

  const closeSelect = async() => {
    document.getElementsByTagName("html")[0].style.overflow = "";
  }

  useEffect(() => {
    consultantName()
  }, [])
  
  
 
  return (
    <Container maxWidth="lg" className="selectdiv">
      <Typography
        textAlign={"center"}
        variant="h1"
        fontSize={"24px"}
        mb={5}
        mt={5}
        fontWeight="500"
      >
        How was your session ?
      </Typography>
      <Typography
        textAlign={"center"}
        variant="h2"
        fontSize={"20px"}
        gutterBottom
      >
        Tell us about your conversation
      </Typography>
      <Typography
        textAlign={"center"}
        variant="h2"
        fontSize={"20px"}
        gutterBottom
        color="#5E17EB"
        mb={3}
        textTransform={'capitalize'}
      >
        with {consultName} on {moment(consultDate).format("MMM Do")}.
      </Typography>
      <Typography variant="h2" fontSize={"16px"} mb={1} color="#000" fontWeight="500">
        People have various needs related to their work, their career, and their
        workplace. Which of your needs as an employee were discussed during yor
        Nomad Grab session?
      </Typography>
      <Typography variant="h2" fontSize={"16px"} color="#6C63FF" mb={2}>
        Please choose all that apply.
      </Typography>
      <Formik
        initialValues={{
          sessionId: session._id,
          tags: [...dname],
          progress: "",
          ratings: "",
          about_situation: "",
          address_situation: "",
          skill_strategy: "",
          checkbox: true,
        }}
        onSubmit={async (values) => {
          setIsProgress(true);
          const response = await Api.post("/customer/session-feedback", values);
          if (response.data.statusCode === 200) {
            Swal.fire({
                title: "Success",
                text: "Feedback Completed Successfully",
                icon: "success",
                confirmButtonText: "OK",
              }).then(function () {
                window.location.href = "/home";
              });
            setIsProgress(false);
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.errorMsg,
              icon: "error",
              confirmButtonText: "OK",
            }).then(function () {
              window.location.href = "/home";
            });
            setIsProgress(false);
          }
        }}
        validationSchema={Yup.object().shape({
          tags: Yup.array().min(1, "At least one item needs to be here").required(),
          progress: Yup.string().oneOf(['sdisagree','disgree','neutral','agree','sagree'], "Required").required("Please select any one of the options to proceed"),
          ratings: Yup.number().required("Please rate your session with the consultant").nullable("Please rate your session with the consultant"),
          about_situation: Yup.string().oneOf(['sdisagree','disgree','neutral','agree','sagree'], "Required").required("Please select any one of the options to proceed"),
          address_situation: Yup.string().oneOf(['sdisagree','disgree','neutral','agree','sagree'], "Required").required("Please select any one of the options to proceed"),
          skill_strategy: Yup.string().oneOf(['sdisagree','disgree','neutral','agree','sagree'], "Required").required("Please select any one of the options to proceed"),
        })}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <Box onSubmit={handleSubmit} component="form">
          <FormControl fullWidth >
            <InputLabel id="tags">Tag</InputLabel>

            <Select
              multiple={true}
              name="tags"
              labelId="tags"
              id="tags"
              value={values.tags}
              onChange= {handleChange}
              input={<OutlinedInput label="Tag" name="tags" />}
              renderValue={(selected) => renderValue(selected)}
              MenuProps={MenuProps}
              onBlur={handleBlur}
              error={errors.tags && touched.tags}
              helpertext={errors.tags && touched.tags && errors.tags}
              disableScrollLock={true}
              onOpen={openSelect}
              onClose={closeSelect}
            >
              {names.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={values.tags.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage
              name="tags"
              render={(msg) => (
                <FormHelperText error={true}>{msg}</FormHelperText>
              )}
            />
          </FormControl>
            {/* 1st que end */}

            <FormControl
              sx={{ justifyContent: "space-between", display: "flex" }}
              className="border-bg"
            >
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography
                  variant="h2"
                  fontSize={"16px"}
                  mt={5}
                  fontWeight="500" color="#000"
                  mb={1}
                >
                  Please evalute the following statements about yourself.
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={"16px"}
                  color="#6C63FF"
                  mb={2}
                >
                  During my Nomad Grab session, I have made progress regarding my
                  needs that I discussed with my coach.
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="progress"
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  position: "relative",
                }}
                value={values.progress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.progress && touched.progress}
                helpertext={errors.progress && touched.progress && errors.progress}
              >
                <FormControlLabel
                  value="sdisagree"
                  control={<Radio />}
                  label="Strongly disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="disgree"
                  control={<Radio />}
                  label="Disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="neutral"
                  control={<Radio />}
                  label="Neutral"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="agree"
                  control={<Radio />}
                  label="Agree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="sagree"
                  control={<Radio />}
                  label="Strongly agree"
                  sx={{ display: "grid" }}
                />
              </RadioGroup>
              <ErrorMessage
              name="progress"
              render={(msg) => (
                <FormHelperText style={{marginTop:'45px'}} error={true}>{msg}</FormHelperText>
              )}
            />
            </FormControl>
            {/* 2nd que end */}

            <Typography
              variant="h2"
              fontSize={"16px"}
              mt={8}
              mb={2}
              fontWeight="500"
            >
              How would you rate your experience with this coach?
            </Typography>
            <StyledRating
              className="starrate"
              name="ratings"
              type="number"
              sx={{
                display: "flex", marginRight: '5rem'
              }}
              value={parseInt(values.ratings)}
              onChange={handleChange}
              size="large"
              mb={2}
              onBlur={handleBlur}
              error={errors.ratings && touched.ratings}
              helpertext={errors.ratings && touched.ratings && errors.ratings}
            />
            <ErrorMessage
              name="ratings"
              render={(msg) => (
                <FormHelperText style={{marginLeft:'2rem'}} error={true}>{msg}</FormHelperText>
              )}
            />
            

            <FormControl
              sx={{ justifyContent: "space-between", display: "flex" }}
              className="border-bg"
            >
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography
                  variant="h2"
                  fontSize={"16px"}
                  mt={5}
                  mb={2}
                  fontWeight="500" color="#000" 
                >
                  I feel more positive about my situation.
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="about_situation"
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  position: "relative",
                }}
                value={values.about_situation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.about_situation && touched.about_situation}
                helpertext={errors.about_situation && touched.about_situation && errors.about_situation}
              >
                <FormControlLabel
                  value="sdisagree"
                  control={<Radio />}
                  label="Strongly disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="disgree"
                  control={<Radio />}
                  label="Disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="neutral"
                  control={<Radio />}
                  label="Neutral"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="agree"
                  control={<Radio />}
                  label="Agree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="sagree"
                  control={<Radio />}
                  label="Strongly agree"
                  sx={{ display: "grid" }}
                />
              </RadioGroup>
              <ErrorMessage
              name="about_situation"
              render={(msg) => (
                <FormHelperText style={{marginTop:'45px'}} error={true}>{msg}</FormHelperText>
              )}
            />
            </FormControl>

            <FormControl
              sx={{ justifyContent: "space-between", display: "flex" }}
              className="border-bg"
            >
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography
                  variant="h2"
                  fontSize={"16px"}
                  mt={8}
                  mb={2}
                  fontWeight="500" color="#000"
                >
                  I am more likely to address my situation.
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="address_situation"
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  position: "relative",
                }}
                value={values.address_situation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.address_situation && touched.address_situation}
                helpertext={errors.address_situation && touched.address_situation && errors.address_situation}
              >
                <FormControlLabel
                  value="sdisagree"
                  control={<Radio />}
                  label="Strongly disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="disgree"
                  control={<Radio />}
                  label="Disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="neutral"
                  control={<Radio />}
                  label="Neutral"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="agree"
                  control={<Radio />}
                  label="Agree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  value="sagree"
                  control={<Radio />}
                  label="Strongly agree"
                  sx={{ display: "grid" }}
                />
              </RadioGroup>
              <ErrorMessage
              name="address_situation"
              render={(msg) => (
                <FormHelperText style={{marginTop:'45px'}} error={true}>{msg}</FormHelperText>
              )}
            />
            </FormControl>
            {/* 5th que end */}

            <FormControl
              sx={{ justifyContent: "space-between", display: "flex" }}
              className="border-bg"
            >
              <FormLabel id="demo-row-radio-buttons-group-label">
                {" "}
                <Typography
                  variant="h2"
                  fontSize={"16px"}
                  mb={2}
                  mt={8}
                  fontWeight="500" color="#000"
                >
                  Thanks to my Nomad Grab session. I have a new skill and
                  strategy that I can apply moving forward.
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="skill_strategy"
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  position: "relative",
                }}
                value={values.skill_strategy}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.skill_strategy && touched.skill_strategy}
                helpertext={errors.skill_strategy && touched.skill_strategy && errors.skill_strategy}
              >
                <FormControlLabel
                  variant="solid"
                  value="sdisagree"
                  control={<Radio />}
                  label="Strongly disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  variant="solid"
                  value="disgree"
                  control={<Radio />}
                  label="Disagree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  variant="solid"
                  value="neutral"
                  control={<Radio />}
                  label="Neutral"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  variant="solid"
                  value="agree"
                  control={<Radio />}
                  label="Agree"
                  sx={{ display: "grid" }}
                />
                <FormControlLabel
                  variant="solid"
                  value="sagree"
                  control={<Radio />}
                  label="Strongly agree"
                  sx={{ display: "grid" }}
                />
              </RadioGroup>
              <ErrorMessage
              name="skill_strategy"
              render={(msg) => (
                <FormHelperText style={{marginTop:'45px'}} error={true}>{msg}</FormHelperText>
              )}
            />
            </FormControl>

            {/* 6th que end */}
            <FormControlLabel
              name="checkbox"
              value={values.checkbox}
              sx={{ marginTop: 8 }}
              control={<Checkbox defaultChecked />}
              onChange={handleChange}
              label=" Nomad Grad may email me directly to request more information about my feedback."
            />

            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{
                marginTop:'20px',
                backgroundColor: "#5E17EB",
                fontSize: "18px",
                padding: "10px 30px",
                display:"block"
              }}
            >
              {!isProgress && "Submit"}
              {isProgress && <CircularProgress />}
            </Button>
          </Box>
        )}
      </Formik>
    </Container>
  );
};

export default Feedback;
