import React,{useState, useEffect} from 'react'
import Api from '../../../../Api'
import CompleteProfile1 from './CompleteProfile1'
import CompleteProfile2 from './CompleteProfile2'
import CompleteProfile3 from './CompleteProfile3'
import CompleteProfile4 from './CompleteProfile4'

const Profile = () => {
    const [data, setData] = useState({});
    const [openPage, setOpenPage] = useState();

    const handleData=async()=>{
        const response = await Api.get("/customer/customer-details");

        if(response.data.data && !response.data.data['title']){
            response.data.data['title'] = "";
        }

        if(response.data.data && !response.data.data['currentNeed']){
            response.data.data['currentNeed'] = "";
        }

        setData({...response.data.data})
        setOpenPage('first');
        console.log(response.data,'44444')
    }
    useEffect(() => {
      handleData()
    }, []);
  return (
    <div>
        {openPage == 'first' &&
            <CompleteProfile1
            data={data}
            setData={setData}
            setOpenPage={setOpenPage}
            />
        }

        {openPage == 'second' &&
            <CompleteProfile2
            data={data}
            setData={setData}
            setOpenPage={setOpenPage}
            />
        }

        {openPage == 'third' &&
            <CompleteProfile3
            data={data}
            setData={setData}
            setOpenPage={setOpenPage}
            />
        }

        {openPage == 'forth' &&
            <CompleteProfile4
            data={data}
            setData={setData}
            setOpenPage={setOpenPage}
            />
        }
    </div>
  )
}

export default Profile