import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Link } from '@mui/material';
import "./style.css";

const Footer = () => {
  const baseUrl = 'https://nomadgrab.com/';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: '0px', md: '15px' },
        textAlign: 'center',
        paddingBottom: '5px',
        fontSize: '14px',
        lineHeight: { xs: '25px', md: '36px' },
      }}
    >
      <Typography
        variant="div"
        align="center"
        className="mullish weight-600"
      >
        ©2023 Nomad Grab, LLC
      </Typography>
      <Typography
        variant="div"
        align="center"
        sx={{
          transform: 'translateY(-5px)',
          display: { xs: 'none', md: 'block' },
        }}
        className="mullish weight-600"
      >
        .
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: { xs: '10px', md: '15px' },
        }}
      >
        <Typography
          variant="div"
          align="center"
          sx={{ cursor: 'pointer' }}
        >
          <Link
            color="inherit"
            underline="none"
            href={baseUrl + 'how-it-works/'}
            className="mullish weight-600"
          >
            How It Works
          </Link>
        </Typography>
        <Typography
          variant="div"
          align="center"
          sx={{ transform: 'translateY(-5px)' }}
          className="mullish weight-600"
        >
          .
        </Typography>

        <Typography
          variant="div"
          align="center"
          sx={{ cursor: 'pointer' }}
        >
          <Link
            color="inherit"
            underline="none"
            href={baseUrl + 'how-it-works/#faq'}
            className="mullish weight-600"
          >
            FAQ
          </Link>
        </Typography>
        <Typography
          variant="div"
          align="center"
          sx={{ transform: 'translateY(-5px)' }}
          className="mullish weight-600"
        >
          .
        </Typography>

        <Typography
          variant="div"
          align="center"
          sx={{ cursor: 'pointer' }}
        >
          <Link
            color="inherit"
            underline="none"
            href={baseUrl + 'privacy/'}
            className="mullish weight-600"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
