import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomeImg from '../../../images/home-img.svg';
import CalendarImg from '../../../images/calendar.svg';
import ProfileImg from '../../../images/profile.svg';
import Zoom from '../../../images/zoom.png';
import Feedback from '../../../images/feed.svg';
import Api from '../../../Api';
import { useState } from 'react';

const theme = createTheme();
const Consultant = () => {
  const [consultantData, setConsultantData] = useState([]);
  const navigate = useNavigate();
  const getConsultantData = async () => {
    const response = await Api.get('/consultant/consultant-details');
    setConsultantData(response.data.data);
  };
  useEffect(() => {
    getConsultantData();
  }, []);

  useEffect(() => {
    if (
      consultantData.firstName === '' ||
      consultantData.backpackerOrExpat === '' ||
      consultantData.tellUs === '' ||
      consultantData.consultantCountries?.length === 0 ||
      consultantData.languages?.length === 0 ||
      consultantData.linkedInLink === ''
    ) {
      console.log('one of the filed is empty');
      navigate('/consultant/updateprofilecons');
    }
  }, [consultantData]);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Box
          className="home-banner theme-padding"
          sx={{ display: { xs: 'block', sm: 'flex' } }}
          justifyContent="space-between"
        >
          <Container>
            <Box
              className="home-banner-inner"
              sx={{ display: { xs: 'block', sm: 'flex' } }}
              justifyContent="space-between"
            >
              <Box className="home-banner-col">
                <Typography
                  variant="h1"
                  gutterBottom
                  fontWeight={700}
                  fontSize={'36px'}
                  color={'#ffffff'}
                >
                  Welcome Back
                </Typography>
              </Box>
              <Box className="home-banner-col">
                <Box
                  className=""
                  component="img"
                  sx={{}}
                  alt="Home banner img"
                  src={HomeImg}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
      <Container>
        <Box className="action-section">
          <Box className="action-section-inner">
            <Box className="action-item">
              <Box className="action-img">
                <Link to="/consultant/availableslots">
                  <Box
                    className=""
                    component="img"
                    sx={{}}
                    alt="calendar image"
                    src={CalendarImg}
                  />
                </Link>
              </Box>

              <Box className="action-title">
                <Link
                  to="/consultant/availableslots"
                  style={{ 'text-decoration': 'none', color: 'black' }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    fontWeight="700"
                    gutterBottom
                  >
                    Calender and Available slots
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box className="action-item">
              <Box className="action-img">
                <Link to="/consultant/updateprofilecons">
                  <Box
                    className=""
                    component="img"
                    sx={{}}
                    alt="calendar image"
                    src={ProfileImg}
                  />
                </Link>
              </Box>
              <Box className="action-title">
                <Link
                  to="/consultant/updateprofilecons"
                  style={{ 'text-decoration': 'none', color: 'black' }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    fontWeight="700"
                    gutterBottom
                  >
                    Complete your profile
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'#5E17EB'}
                    fontSize={'20px'}
                    gutterBottom
                  >
                    Finish completing your profile.
                  </Typography>
                </Link>
              </Box>
            </Box>

            <Box className="action-item">
              <Box className="action-img">
                <Link to="/consultant/upcomingSessions">
                  <Box
                    className=""
                    component="img"
                    sx={{}}
                    alt="calendar image"
                    src={Zoom}
                  />
                </Link>
              </Box>
              <Box className="action-title">
                <Link
                  to="/consultant/upcomingSessions"
                  style={{ 'text-decoration': 'none', color: 'black' }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    fontWeight="700"
                    gutterBottom
                  >
                    Zoom sessions
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'#5E17EB'}
                    fontSize={'20px'}
                    gutterBottom
                  >
                    See your past and upcoming session details.
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box className="action-item">
              <Box className="action-img">
                <Link to="/consultant/userfeedback">
                  <Box
                    className=""
                    component="img"
                    sx={{}}
                    alt="calendar image"
                    src={Feedback}
                  />
                </Link>
              </Box>
              <Box className="action-title">
                <Link
                  to="/consultant/userfeedback"
                  style={{ 'text-decoration': 'none', color: 'black' }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    fontWeight="700"
                    gutterBottom
                  >
                    Feedback and Advice given by user
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Consultant;
