import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  FormControl,
  InputLabel,
  CircularProgress,
  NativeSelect,
  OutlinedInput,
} from '@mui/material';
import {
  EmailOutlined,
  Lock,
  Wc,
  Person,
  Phone,
  Public,
  Apartment,
} from '@mui/icons-material';
import LoginLeft from '../images/login-left.svg';
import LoginRight from '../images/login-right.svg';
import { Formik, Field, ErrorMessage } from 'formik';
import { get_countries } from '../Front/Customer/redux/master_data';
import Swal from 'sweetalert2';
import Api from '../Api';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  // phone: '',
  country: '',
  // city: '',
  gender: '',
};

const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .trim()
    .min(2, 'First Name must be at least 2 characters.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  lastName: Yup.string()
    .trim()
    .min(2, 'Last Name must be at least 2 characters.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .required('Required'),
  email: Yup.string().email('Invalid email.').required('Required'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character.'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match.'),
  // phone: Yup.number()
  //   .required('Phone number required field')
  //   .typeError('Phone number can only be a number'),
  country: Yup.string().required('Please, select country'),
  // city: Yup.string().required('Please, select city'),
  gender: Yup.string().required('Required'),
});

const Register = (props) => {
  const dispatch = useDispatch();

  // const [country, setCountry] = useState(null);
  // const [cities, setCities] = useState([]);
  const [isProgress, setIsProgress] = useState(false);

  const countries = useSelector((state) => {
    return get_countries(state, dispatch);
  });

  // const get_citites = async (countryId) => {
  //   console.log('countryId', countryId);
  //   if (countryId !== country) {
  //     setCountry(countryId);
  //     const res = await Api.get('/cities?countryId=' + countryId);
  //     setCities([...res.data.data]);
  //   }
  // };

  return (
    <React.Fragment>
      <Container maxWidth="xl" className="login-main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography mt={2} component="h1" variant="h4">
            Create Nomad Account
          </Typography>

          <Formik
            initialValues={INITIAL_FORM_STATE}
            onSubmit={async (values, { resetForm }) => {
              setIsProgress(true);
              values['timezone'] =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
              const response = await Api.post('/register', values);
              if (response.data.statusCode === 200) {
                resetForm(INITIAL_FORM_STATE);
                Swal.fire({
                  title: 'Success',
                  text: 'Registration Completed Successfully',
                  icon: 'success',
                  confirmButtonText: 'OK',
                }).then(function () {
                  window.location.href = '/login';
                });
                setIsProgress(false);
              } else {
                Swal.fire({
                  title: 'Error',
                  text: response.data.errorMsg,
                  icon: 'error',
                  confirmButtonText: 'OK',
                });
                setIsProgress(false);
              }
            }}
            validationSchema={FORM_VALIDATION_SCHEMA}
          >
            {(props) => {
              const {
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
              } = props;
              return (
                <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    type="text"
                    id="firstName"
                    value={values.firstName}
                    autoComplete="off"
                    margin="normal"
                    required
                    label="First Name"
                    name="firstName"
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName}
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type="text"
                    fullWidth
                    required
                    id="lastName"
                    value={values.lastName}
                    autoComplete="off"
                    margin="normal"
                    label="Last Name"
                    name="lastName"
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    error={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type="text"
                    id="email"
                    value={values.email}
                    fullWidth
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="Email Address"
                    name="email"
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type="password"
                    id="password"
                    value={values.password}
                    fullWidth
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="Password"
                    name="password"
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type="password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    autoComplete="off"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    required
                    label="Confirm Password"
                    name="confirmPassword"
                    error={errors.confirmPassword && touched.confirmPassword}
                    helperText={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <TextField
                    sx={{ mb: 3 }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="number"
                    id="phone"
                    value={values.phone}
                    fullWidth
                    autoComplete="off"
                    margin="normal"
                    required
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone && touched.phone}
                    helperText={errors.phone && touched.phone && errors.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
                    <InputLabel
                      id="country"
                      required
                      error={errors.country && touched.country}
                    >
                      Country
                    </InputLabel>
                    <Field
                      as={NativeSelect}
                      input={<OutlinedInput label="Country" name="country" />}
                      required
                      variant="outlined"
                      label="Country"
                      id="country"
                      value={values.country}
                      name="country"
                      onChange={(e) => {
                        handleChange(e);
                        // get_citites(e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={errors.country && touched.country}
                      helperText={touched.country && errors.country}
                      startAdornment={
                        <InputAdornment position="start">
                          <Public />
                        </InputAdornment>
                      }
                    >
                      {countries.map((country) => {
                        return (
                          <option
                            component="li"
                            value={country._id}
                            key={country._id}
                          >
                            {country.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="country"
                      render={(msg) => (
                        <FormHelperText
                          error={errors.country && touched.country}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    />
                  </FormControl>
                  {/*<FormControl fullWidth sx={{ mb: 3 }}>*/}
                  {/*  <InputLabel*/}
                  {/*    id="City"*/}
                  {/*    required*/}
                  {/*    errors={errors.city && touched.city}*/}
                  {/*  >*/}
                  {/*    City*/}
                  {/*  </InputLabel>*/}
                  {/*  <Field*/}
                  {/*    required*/}
                  {/*    as={NativeSelect}*/}
                  {/*    input={<OutlinedInput label="City" name="city" />}*/}
                  {/*    variant="outlined"*/}
                  {/*    label="City"*/}
                  {/*    id="city"*/}
                  {/*    value={values.city}*/}
                  {/*    name="city"*/}
                  {/*    onChange={handleChange}*/}
                  {/*    onBlur={handleBlur}*/}
                  {/*    error={errors.city && touched.city}*/}
                  {/*    helperText={errors.city && touched.city && errors.city}*/}
                  {/*    startAdornment={*/}
                  {/*      <InputAdornment position="start">*/}
                  {/*        <Apartment />*/}
                  {/*      </InputAdornment>*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    {cities.map((city) => {*/}
                  {/*      return (*/}
                  {/*        <option*/}
                  {/*          component="li"*/}
                  {/*          value={city._id}*/}
                  {/*          key={city._id}*/}
                  {/*        >*/}
                  {/*          {city.name}*/}
                  {/*        </option>*/}
                  {/*      );*/}
                  {/*    })}*/}
                  {/*  </Field>*/}
                  {/*  <ErrorMessage*/}
                  {/*    name="city"*/}
                  {/*    render={(msg) => (*/}
                  {/*      <FormHelperText error={true}>{msg}</FormHelperText>*/}
                  {/*    )}*/}
                  {/*  />*/}
                  {/*</FormControl>*/}
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel
                      id="gender"
                      required
                      error={errors.gender && touched.gender}
                    >
                      Gender
                    </InputLabel>

                    <NativeSelect
                      name="gender"
                      labelId="gender"
                      id="gender"
                      required
                      value={values.gender}
                      onChange={handleChange}
                      input={<OutlinedInput label="Gender" name="gender" />}
                      label="Gender"
                      margin="normal"
                      onBlur={handleBlur}
                      error={errors.gender && touched.gender}
                      helperText={
                        errors.gender && touched.gender && errors.gender
                      }
                      disableScrollLock={true}
                      style={{ maxHeight: 200, overflow: 'auto' }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Wc />
                        </InputAdornment>
                      }
                    >
                      <option value={''}></option>
                      <option value={'male'}>Male</option>
                      <option value={'female'}>Female</option>
                      <option value={'other'}>Other</option>
                    </NativeSelect>
                    <ErrorMessage
                      name="gender"
                      render={(msg) => (
                        <FormHelperText error={true}>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>

                  <Button
                    onClick={handleSubmit}
                    disabled={isProgress}
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="bg-theme"
                    size="large"
                    sx={{ mt: 3, backgroundColor: '#5e17eb' }}
                  >
                    {!isProgress && 'Register'}
                    {isProgress && <CircularProgress />}
                  </Button>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 1, mb: 5 }}
                  >
                    <Grid item>
                      Already Registered?&nbsp;
                      <Link
                        href="/login"
                        underline="none"
                        variant="body3"
                        sx={{
                          color: '#5e17eb',
                          textDecoration: 'none',
                        }}
                      >
                        Login.
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Formik>
        </Box>
        <Box
          className="login-left"
          component="img"
          sx={{
            height: 300,
            width: 300,
            maxHeight: { xs: 150, md: 150 },
            maxWidth: { xs: 150, md: 150 },
            display: { xs: 'none', sm: 'block' },
          }}
          alt="The house from the offer."
          src={LoginLeft}
        />
        <Box
          className="login-right"
          component="img"
          sx={{
            height: 250,
            width: 350,
            maxHeight: { xs: 150, md: 150 },
            maxWidth: { xs: 150, md: 150 },
          }}
          alt="The house from the offer."
          src={LoginRight}
        />
      </Container>
    </React.Fragment>
  );
};

export default Register;
