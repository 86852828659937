import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CardMedia,
  Container,
  TablePagination
} from "@mui/material";
import moment from "moment";
import report from "../../../../images/report.svg";
import success from "./../../../../images/success.svg";
import Api from "../../../../Api";

const SessionList = (props) => {
  const navigate = useNavigate();
  const [session, setSession] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const getSessionList = async(page=1) => {
    setPage(page);

    const response = await Api.get("/customer/completed-session-list?page="+page);
    setSession([...response.data.data.docs]);
    setTotalCount(response.data.data.total);
  }

  const handleChangePage = (event = null, newPage=1) => {
    getSessionList(newPage+1);
  };

  const openFeedback =(item)=>{
    navigate("/feedback", {state: item})
  }

  useEffect(() => {
    getSessionList(1);
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Box textAlign={"center"}>
        <Box
          className=""
          component="img"
          sx={{
            height: 90,
            width: 90,
            maxHeight: { xs: 60, md: 90 },
            maxWidth: { xs: 60, md: 90 },
          }}
          alt="Report icon"
          src={report}
        />
        <Typography component="h2" variant="h5" gutterBottom>
          Rate Your Sessions
        </Typography>
      </Box>
      {session.length > 0 ? session.map((item) => {
        let status = "Completed session";
        let imageName = success;
        if(item.status === "completed"){
          status = "Completed session";
          imageName = success;
        }
        
        return (
          <Card sx={{ display: "flex", pl: 2, m: 2, cursor:"pointer" }} key={item._id} onClick={e => openFeedback(item)}>
            <CardMedia
              component="img"
              sx={{
                height: 90,
                width: 90,
                maxHeight: { xs: 60, md: 90 },
                maxWidth: { xs: 60, md: 90 },
                p: 2,
              }}
              alt={status}
              src={imageName}
            />
            <CardContent sx={{ borderLeft: 1, borderColor: "#C8C8C8" }}>
              <Typography component="div" variant="h5"  value={item._id}>
                {/* Mon, Jun 27 at 5:00 PM EDT */}
                {moment(item.dateTime).format("ddd, MMM D")} at {moment(item.dateTime).format("hh:mm A")}
              </Typography>
              <Typography variant="subtitle1" color="#5E17EB" value={item._id}>
                {status}
              </Typography>
            </CardContent>
          </Card>
        );
      }
      ):<Typography m={2} component="div" sx={{ textAlign:'center'}} variant="h5" >No sessions to display</Typography>
    }

  {session.length > 0  &&
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={totalCount}
        rowsPerPage={10}
        page={page-1}
        onPageChange={handleChangePage}
      />
  }
    </Container>
  );
};

export default SessionList;
