import React, { useCallback, useMemo } from 'react';
import {
  // Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import './index.css';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { get_countries } from '../../redux/master_data';
import { useEffect } from 'react';
import Api from '../../../../Api';
import { useNavigate } from 'react-router-dom';

const Profile = ({ handleProfileModalClose }) => {
  const [isProgress, setIsProgress] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [langList, setLangList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: '65%' },
    // height: { xs: '85%', md: '62%' },
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: 2,
    paddingY: '26px',
  };
  const nextButton = {
    boxShadow: 1,
    width: { xs: '100%', md: '35%' },
    paddingX: { xs: '20px' },
    paddingY: { xs: '10px' },
    borderRadius: '25px',
    backgroundColor: '#3F4754',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 5,
      backgroundColor: '#3F4754',
    },
    fontSize: '16px',
    lineHeight: '20px',
  };

  const getLanguages = async (value) => {
    const res = await Api.get('/customer/language-list?country=' + value);
    if (res?.data?.statusCode === 200) {
      setLangList(res?.data?.data);
    }
  };
  const getProfileData = async () => {
    const response = await Api.get('/customer/customer-details');
    if (response.data.statusCode === 200) {
      setProfileData(response?.data?.data);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);

  const submitProfileData = async (data) => {
    setIsProgress(true);
    const response = await Api.post('/customer/edit-profile', data);
    if (response.data.statusCode === 200) {
      console.log('success', response.data);
      //   navigate('/completeprofile2')
      setIsProgress(false);
      handleProfileModalClose();
      navigate('/booksession');
    } else {
      console.log(response.data.errorMsg);
      setIsProgress(false);
    }
  };

  const countries = useSelector((state) => {
    return get_countries(state, dispatch);
  });
  const formik = useFormik({
    initialValues: {
      city: '',
      nativeLanguage: '',
      languages: [],
      otherCities: [],
    },
    validationSchema: Yup.object().shape({
      // firstName: Yup.string().required('Required*'),
      // lastName: Yup.string().required('Required*'),
      // email: Yup.string().email().required('Required*'),
      // country: Yup.string().required('Required*'),
      // surname: Yup.string().required('Required*'),
      // address: Yup.string().required('Required*'),
    }),
    onSubmit: (values) => {
      const userData = {
        city: values.city,
        nativeLanguage: values.nativeLanguage,
        languages: values.languages,
        otherCities: values.otherCities.split(','),
        // title: values.firstName + '' + values.lastName,
      };
      console.log(userData);
      submitProfileData(userData);
    },
  });
  // useEffect(() => {
  //   console.log(formik.values);
  // });
  useEffect(() => {
    formik.setFieldValue('nativeLanguage', profileData.nativeLanguage);
    formik.setFieldValue('languages', profileData.languages);
    formik.setFieldValue('city', profileData.city);
    formik.setFieldValue('otherCities', profileData?.otherCities?.join(','));
  }, [profileData]);

  // console.log('profile data', profileData);

  useEffect(() => {
    // get_cities(profileData.country);
    getLanguages(profileData.country);
  }, [profileData]);

  return (
    <Box sx={modalStyle}>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: '18px', md: '24px' },
            lineHeight: { xs: '20px', md: '24px' },
            textAlign: 'center',
            paddingY: { xs: '8px' },
            fontWeight: '700',
          }}
          className="mullish"
        >
          Complete your profile
        </Typography>
        <Typography
          sx={{
            paddingY: '10px',
            paddingX: { xs: '10px' },
            textAlign: 'center',
            fontSize: { xs: '16px', md: '18px' },
            lineHeight: { xs: '20px', md: '24px' },
            fontWeight: '500',
          }}
          className="inter-family"
        >
          Our hand-picked professional nomads can help you iron out all parts of
          your trip
        </Typography>

        <Grid
          container
          rowSpacing={{ xs: 2, md: 4 }}
          columnSpacing={8}
          sx={{
            paddingX: { xs: '20px', md: '80px' },
            paddingY: { xs: '10px', md: '30px' },
          }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: '6px', md: '14px' },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-500"
              >City</Typography>
              <TextField
                fullWidth
                type="text"
                id="city"
                autoComplete="off"
                name="city"
                value={formik.values.city}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-400"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: '6px', md: '14px' },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-500"
              >Other Cities</Typography>
              <TextField
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-400"
                fullWidth
                type="text"
                id="otherCities"
                autoComplete="off"
                name="otherCities"
                value={formik.values.otherCities}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                helperText="Cities must be seperated by comma"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: '6px', md: '14px' },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-500"
              >Native Language</Typography>
              <Select
                displayEmpty
                value={formik.values.nativeLanguage}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                sx={{
                  width: '100%',
                  height: '56px',
                  outline: 'none',
                  boxShadow: '0px 1px 2px rgba(0,0,0,0.05)',
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-400"
                id="nativeLanguage"
                name="nativeLanguage"
              >
                {langList.map((language) => {
                  return (
                    <MenuItem value={language.langName} key={language.langName}>
                      {language.langName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ mt: '-8px', ml: '8px' }}>
                {formik.touched.nativeLanguage && formik.errors.nativeLanguage}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: '6px', md: '14px' },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-500"
              >Other Languages</Typography>
              <Select
                displayEmpty
                multiple
                value={formik.values.languages}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                sx={{
                  width: '100%',
                  height: '56px',
                  outline: 'none',
                  boxShadow: '0px 1px 2px rgba(0,0,0,0.05)',
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className="inter-family weight-400"
                id="languages"
                name="languages"
              >
                {langList.map((language) => {
                  return (
                    <MenuItem value={language.langName} key={language.langName}>
                      {language.langName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ mt: '-8px', ml: '8px' }}>
                {formik.touched.language && formik.errors.language}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            textAlign: 'center',
            paddingTop: { xs: '28px', md: '24px' },
            paddingBottom: '5px',
            paddingX: { xs: '20px' },
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={nextButton}
            className="mullish weight-700"
          >
            {isProgress ? <CircularProgress /> : 'Next'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Profile;
