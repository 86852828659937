import React,{useState} from 'react'
import { Box, FormHelperText, Typography, NativeSelect,RadioGroup, Button, Grid, OutlinedInput, InputLabel, FormLabel, FormControlLabel, Radio, FormControl, CircularProgress } from '@mui/material'
import { Formik, ErrorMessage } from 'formik'
import usericoimg from '../../../../images/usr-icon.svg'
import Api from '../../../../Api'
import * as Yup from 'yup';



const CompleteProfile2 = ({data, setOpenPage, setData}) => {
  const [isProgress, setIsProgress] = useState(false);

  return (
    <Box  sx={{ backgroundColor: "#fff", borderRadius: '30px', maxWidth: '900px', width: "95%", margin: '60px auto', }}>
      <Box p={3} sx={{ borderRadius: "10" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Box
              component="img"
              sx={{
                height: 100,
                width: 100,
                maxHeight: { xs: 200, md: 250 },
                maxWidth: { xs: 200, md: 250 },
                outline: '0', marginBottom: '0',
              }}
              alt="UserAvatar"
              src={usericoimg}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography component="h1" variant="h4" gutterBottom fontSize="36px">
              Demographic Info
            </Typography>
            <Typography variant="body2" gutterBottom color={'#5E17EB'} >
              We know that demographic identities can effort our professional lives. This info allows us to better support you Nomads Grab experience. Your answers will not be seen by your employer. We
              are committed to non-discrimnation at all companies with Nomads Grab.
            </Typography>
          </Grid>

        </Grid>

        <Formik
            initialValues={data}
            onSubmit={async (values) => {
              setIsProgress(true);
                console.log("values", values);
                const response = await Api.post("/customer/demographic-info", values);
                if (response.data.statusCode === 200) {
                    setData(values);
                    console.log('success',response.data)
                    setIsProgress(false);
                    setOpenPage('third');
                } else {
                    console.log(response.data.errorMsg);
                    setIsProgress(false);
                }
            }}
            validationSchema={Yup.object().shape({
              gender: Yup.string().required("Required"),
            })}
        >
          {(props) => {
            const {
              errors,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              values,
              touched,
            } = props;
            return (
              <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
                 <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="ethnicity" >
                        Ethnicity (Optional)
                      </InputLabel>
                      <NativeSelect
                        id="raceOrEthenicity"
                        name="raceOrEthenicity"
                        label="Ethnicity (Optional)"
                        variant="outlined"
                        margin="normal"
                        input={<OutlinedInput label="Ethnicity (Optional)" name="raceOrEthenicity" />}
                        value={values.raceOrEthenicity}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={""}>Prefer Not To Say</option>
                        <option value={"americanNative"}>American Indian or Alaska Native</option>
                        <option value={"asian"}>Asian</option>
                        <option value={"black "}>Black or African American</option>
                        <option value={"latino"}>Hispanic or Latino</option>
                        <option value={"pacific"}>Native Hawaiian or Other Pacific Islander</option>
                        <option value={"white"}>White</option>
                      </NativeSelect>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel required id="gender" >
                        Gender
                      </InputLabel>
                      <NativeSelect
                        id="gender"
                        name="gender"
                        label="Gender"
                        variant="outlined"
                        input={<OutlinedInput label="Ethnicity (Optional)" name="raceOrEthenicity" />}
                        margin="normal"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gender}
                      >
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                        <option value={"other"}>Other</option>
                      </NativeSelect>
                      <ErrorMessage
                        name="gender"
                        render={(msg) => (
                          <FormHelperText error={true}>{msg}</FormHelperText>
                        )}
                      />
                    </FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">LGBTQ+ (Optional)</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="lgbtq"
                    value={values.lgbtq}
                    onChange={(event) => {
                        setFieldValue('lgbtq', event.currentTarget.value);
                    }}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes"/>
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="not_specify" control={<Radio />} label="I’d prefer not to say" />
                
                </RadioGroup>
                <Box
                mt={4}
                display='flex'
                justifyContent='space-between'
            >
                <Button
                    onClick={() => setOpenPage('first')}
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                >
                    Back
                </Button>
                
                <Button
                    onClick={handleSubmit}
                    disabled={isProgress}
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                >
                    {!isProgress && "NEXT"}
                    {isProgress && <CircularProgress />}
                </Button>
                
            </Box>
            </Box>

            )
          }
          }
        </Formik>
      </Box>

    </Box>
  )
}

export default CompleteProfile2