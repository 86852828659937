import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const Consultant = Loadable(lazy(() => import('../views/consultant')));
const AddConsultant = Loadable(lazy(() => import('../views/consultant/add')));
const EditConsultant = Loadable(lazy(() => import('../views/consultant/edit')));
const Customer = Loadable(lazy(() => import('../views/customer')));
const Language = Loadable(lazy(() => import('../views/language')));
const AddLanguage = Loadable(lazy(() => import('../views/language/add')));
const EditLanguage = Loadable(lazy(() => import('../views/language/edit')));
const Session = Loadable(lazy(() => import('../views/session')));
const DemoSession = Loadable(lazy(() => import('../views/demoSession')));
const Help = Loadable(lazy(() => import('../views/help')));
const Report = Loadable(lazy(() => import('../views/report')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'consultant',
            element: <Consultant />
        },
        {
            path: 'add-consultant',
            element: <AddConsultant />
        },
        {
            path: 'edit-consultant',
            element: <EditConsultant />
        },
        {
            path: 'customer',
            element: <Customer />
        },
        {
            path: 'language',
            element: <Language />
        },
        {
            path: 'add-language',
            element: <AddLanguage />
        },
        {
            path: 'edit-language',
            element: <EditLanguage />
        },
        {
            path: 'session',
            element: <Session />
        },
        {
            path: 'demoSession',
            element: <DemoSession />
        },
        {
            path: 'help',
            element: <Help />
        },
        {
            path: 'report',
            element: <Report />
        },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-typography',
        //             element: <UtilsTypography />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-color',
        //             element: <UtilsColor />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-shadow',
        //             element: <UtilsShadow />
        //         }
        //     ]
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'tabler-icons',
        //             element: <UtilsTablerIcons />
        //         }
        //     ]
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },
        // {
        //     path: 'sample-page',
        //     element: <SamplePage />
        // }
    ]
};

export default MainRoutes;
