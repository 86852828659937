import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Modal } from '@mui/material';
import Api from '../../../Api';
import Loader from '../Components/Loader';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';

// const PayPalButton = window?.paypal?.Buttons?.driver('react', {
//   React,
//   ReactDOM,
// });

const paymentPopupStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '350px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  margin: '0 auto',
};

const Payment = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const createOrder = (data, actions, price) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: price,
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    setLoading(true);

    const order_capture = await actions.order.capture();

    const response = await Api.post('/customer/verify-payment', {
      trasactionId: order_capture.id,
      subscription_type: props.price === 32.99 ? 'single' : 'monthly',
    });
    // console.log(response, 'response');
    Swal.fire({
      title: 'Success',
      text: 'One Time Payment Successfully',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then(function () {});
    setLoading(false);
    setOpen(false);

    props.onPaymentComplete(response);
    props.onClose();
    return order_capture;
  };

  const paypalOnError = (err) => {
    console.log('Error');
  };

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      //testing plan ID
      // plan_id: 'P-2CV43021R1252553JMPKQVLA', // Creates the subscription
      // prod plan ID
      plan_id: 'P-2JL08174R12878720MPFIL3A', // Creates the subscription
    });
  };

  const onSubscriptionApprove = async (data, actions) => {
    const response = await Api.post('/customer/verify-payment', {
      trasactionId: data.subscriptionID,
      subscription_type: props.price === 32.99 ? 'single' : 'monthly',
    });
    console.log(data);
    Swal.fire({
      title: 'Success',
      text: 'Subscription Payment Successfully',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then(function () {});
    setLoading(false);
    setOpen(false);

    props.onPaymentComplete(response);
    props.onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{ overflow: 'scroll' }}
      >
        <Box sx={{ ...paymentPopupStyle, width: 400 }}>
          <h2 id="parent-modal-title">Payment</h2>
          {/* {props.price === 32.99 ? (
            <PayPalButton
              createOrder={(data, actions) =>
                createOrder(data, actions, props.price)
              }
              onApprove={(data, actions) => onApprove(data, actions)}
              onError={paypalOnError}
            />
          ) : (
            <PayPalButton
              createSubscription={(data, actions) =>
                createSubscription(data, actions)
              }
              onApprove={(data, actions) =>
                onSubscriptionApprove(data, actions)
              }
              onError={paypalOnError}
            />
          )} */}
          {props.price === 32.99 ? (
            <PayPalScriptProvider
              options={{
                // testing client id
                // 'client-id':
                //   'ATrTFvKEjz0VEwFSwXxVralOUMrkHiqYyrSk6sYFHEXVvclE20m0XaoDb_IfzhXIObyl0olzAZzEix25',
                // prod client id
                'client-id':
                  'AQQd88ixxgpkNdmLtzmNrsmh4PKVJh2PHiBA_LCp-znSeihz5iMywxVtxRmlVjs3L6AFuDFRxd93Qm8k',
              }}
            >
              <PayPalButtons
                createOrder={(data, actions) =>
                  createOrder(data, actions, props.price)
                }
                onApprove={(data, actions) => onApprove(data, actions)}
                onError={paypalOnError}
              />
            </PayPalScriptProvider>
          ) : (
            <PayPalScriptProvider
              options={{
                // testing client id
                // 'client-id':
                //   'ATrTFvKEjz0VEwFSwXxVralOUMrkHiqYyrSk6sYFHEXVvclE20m0XaoDb_IfzhXIObyl0olzAZzEix25',
                // prod client id
                'client-id':
                  'AQQd88ixxgpkNdmLtzmNrsmh4PKVJh2PHiBA_LCp-znSeihz5iMywxVtxRmlVjs3L6AFuDFRxd93Qm8k',
                vault: true,
              }}
            >
              <PayPalButtons
                createSubscription={(data, actions) =>
                  createSubscription(data, actions)
                }
                onApprove={(data, actions) =>
                  onSubscriptionApprove(data, actions)
                }
                onError={paypalOnError}
              />
            </PayPalScriptProvider>
          )}

          {loading && <Loader />}
        </Box>
      </Modal>
    </>
  );
});

export default Payment;
