import axios from "axios";
// import {LoaderManager} from './components/Loader';

// const BASE_URL = "http://localhost:3000";
const BASE_URL = process.env.REACT_APP_API_URL;
const Api = axios.create({
  baseURL: BASE_URL,
});

Api.interceptors.request.use(function (config) {
  // LoaderManager.show();
  if (localStorage.getItem("token") !== null) {
    config.headers.common["Authorization"] = `${localStorage.getItem("token")}`;
  }

  config.headers.common["Content-Type"] = "application/json";

  return config;
});

Api.interceptors.response.use(
  (response) => {
    // LoaderManager.hide();
    return response;
  },
  function (error) {
    // LoaderManager.hide();
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error.response);
  }
);

export default Api;
