import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from '../Admin/routes';

// defaultTheme
import themes from '../Admin/themes';

// project imports
import NavigationScroll from '../Admin/layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);

    if(localStorage.getItem("userType") !== "admin"){
        navigate("/");
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
