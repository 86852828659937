import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import Api from '../../../../Api';
import Payment from '../Payment';
import "../style.css";

const Payments = ({ onClose }) => {
  const [subsMsg, setSubsMsg] = useState('');
  const [status, setStatus] = useState('');
  const [checkPrice, setCheckPrice] = useState(false);
  const [subs, setSubs] = useState(null);

  const paymentRef = useRef();
  const modalStyle = {
    position: 'absolute',
    top: { xs: '56%', md: '54%' },
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100%', md: '90%' },
    height: { xs: '90%', md: '95%' },
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: { xs: 4, md: 24 },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };
  const accessButton = {
    boxShadow: 1,
    height: '30%',
    position: 'relative',
    top: '20px',
    // width: { xs: "100%", md: "35%" },
    paddingX: '20px',
    paddingY: { xs: '10px', md: '5px' },
    borderRadius: '30px',
    marginBottom: { xs: '40px', md: '0px' },
    backgroundColor: '#5E17EB',
    color: 'white',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: 5,
      backgroundColor: '#5E17EB',
    },
    fontSize: '16px',
    lineHeight: '20px',
  };
  const tokenButton = {
    boxShadow: 1,
    height: '30%',
    position: 'relative',
    // top: "20px",
    width: { xs: '100%', md: '100%' },
    paddingX: '25px',
    paddingY: '5px',
    borderRadius: '30px',
    backgroundColor: '#fff',
    color: 'black',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      boxShadow: 5,
      backgroundColor: '#fff',
    },
  };

  const onPaymentComplete = (response) => {
    if (response.data.statusCode === 200) {
      Swal.fire({
        title: 'Success',
        text: 'Payment Successfull!!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(function () {
        window.location.reload();
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: response.data.errorMsg,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const checkSubPrice = (value) => {
    setCheckPrice(value);
    setTimeout(() => {
      paymentRef.current.handleOpen();
    }, 100);
  };

  const subscriptionStatus = async () => {
    const response = await Api.get('/customer/subscription');
    setSubs(response.data.subscription);
    const endSubsDate = new Date(response?.data?.subscription?.endDate);

    const date1 = moment(endSubsDate);
    const date2 = moment(new Date());
    const dateDiff = date1.diff(date2, 'days') + 1;

    if (dateDiff < 4 && dateDiff > 0) {
      setStatus('leftOver');
      return setSubsMsg(
        <Typography color={'error'} sx={{ fontWeight: '500' }}>
          Your subscription is about to expire on{' '}
          {moment(date1).format('MMM Do YYYY')} please recharge. Days left{' '}
          {dateDiff}.{' '}
        </Typography>
      );
    } else if (dateDiff > 4) {
      setStatus('active');
      return setSubsMsg(
        <Typography sx={{ fontWeight: '500' }}>
          You have active subscription until{' '}
          {moment(date1).format('MMM Do YYYY')}.
        </Typography>
      );
    } else if (subs === null) {
      setStatus('noSubs');
      return setSubsMsg(
        <Typography
          color={'error'}
          sx={{ fontWeight: '500' }}
          className="mullish"
        >
          You don’t have an active subscription. Click “Get Access” to start
          your journey
        </Typography>
      );
    } else {
      setStatus('expired');
      return setSubsMsg(
        <Typography color={'error'} sx={{ fontWeight: '500' }}>
          Your plan has been expired. Please recharge to avail services.
        </Typography>
      );
    }
  };

  useEffect(() => {
    subscriptionStatus();
  }, []);

  // useEffect(() => {
  //   if (checkPrice) {
  //     const script = document.createElement('script');
  //     script.src =
  //       'https://www.paypal.com/sdk/js?client-id=AWROhOsKySoaBs-dJAI59KFMpwS5pvHz2osrcicj2vFDWwVXS-K8QILfA22btw7TRRVBvsm7GfK3YS8V&components=buttons&vault=true&intent=subscription&currency=USD';
  //     script.async = true;
  //     document.body.appendChild(script);
  //   } else {
  //     const script = document.createElement('script');
  //     script.src =
  //       'https://www.paypal.com/sdk/js?client-id=AWROhOsKySoaBs-dJAI59KFMpwS5pvHz2osrcicj2vFDWwVXS-K8QILfA22btw7TRRVBvsm7GfK3YS8V&components=buttons&currency=USD';
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }
  // }, []);

  return (
    <Box sx={modalStyle}>
      <Payment
        ref={paymentRef}
        onPaymentComplete={onPaymentComplete}
        price={checkPrice ? 59.99 : 32.99}
        onClose={onClose}
      />
      <Box
        onClick={onClose}
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingRight: '5px',
          paddingTop: '5px',
          cursor: 'pointer',
          zIndex: '1',
        }}
      >
        <CloseOutlinedIcon
          sx={{
            backgroundColor: '#E3E3ED',
            borderRadius: '50%',
            padding: '5px',
          }}
        />
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          lineHeight: '48px',
          fontWeight: '700',
          paddingtop: { xs: '0px', md: '10px' },
          paddingBottom: '40px',
          transform: { xs: 'translateY(-30px)', md: 'translateY(0px)' },
          position: 'relative',
          zIndex: '0',
        }}
        className="mullish"
      >
        Pricing
      </Typography>
      <Box sx={{ paddingX: { xs: '16px', md: '70px' } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'start', md: 'space-between' },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '36px', md: '48px' },
                lineHeight: { xs: '48px', md: '48px' },
                fontFamily: "'Playfair Display', serif !important",
                fontWeight: '800',
                letterSpacing: { xs: '-1px', md: '-2px' },
              }}
            >
              Everything you need for{' '}
              <Box
                component="span"
                sx={{
                  color: '#5E17EB',
                  fontFamily: 'Playfair Dispay !important',
                  display: { xs: 'inline-block', md: 'inline' },
                }}
              >
                $
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    transform: 'translateY(5px)',
                    fontFamily: 'Playfair Dispay !important',
                  }}
                >
                  59.99
                </Box>{' '}
                a month
              </Box>
            </Typography>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: '28px',
                color: '#3F4754',
                paddingTop: '8px',
              }}
              className="mullish weight-400"
            >
              Includes every feature we offer plus unlimited consultations.
            </Typography>
            <Typography>{subsMsg}</Typography>
          </Box>
          {subs?.remainingSession === 0 ||
          subs?.subscription_type === 'single' ||
          subs === null ? (
            <Button
              sx={accessButton}
              onClick={() => checkSubPrice(true)}
              className="mullish weight-700"
            >
              Get Access
            </Button>
          ) : null}
        </Box>
        <Divider sx={{ paddingY: '10px' }} />
        <Grid
          container
          spacing={{ xs: 0, md: 2 }}
          sx={{
            paddingTop: { xs: '40px', md: '60px' },
            paddingBottom: { xs: '15px', md: '40px' },
          }}
        >
          <Grid item md={4}>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '700',
                  color: '#5E17EB',
                  textTransform: 'uppercase',
                }}
                className="mullish"
              >
                Everything you need
              </Typography>
              <Typography
                sx={{
                  fontSize: '30px',
                  lineHeight: '36px',
                }}
                className="mullish weight-800"
              >
                All-in-one travel guide
              </Typography>
              <Typography
                sx={{
                  fontSize: '18px',
                  lineHeight: '28px',
                  color: '#3F4754',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
                className="mullish weight-400"
              >
                Our comprehensive travel guide includes personalized
                consultation and resources to help you plan your trip.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} sx={{ paddingTop: { xs: '16px', md: '0px' } }}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#3F4754',
                    }}
                    disableTypography
                    className="mullish weight-400"
                  >
                    Unlimited 1-1 consultations
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#3F4754',
                    }}
                    disableTypography
                    className="mullish weight-400"
                  >
                    Access to a vast library of travel resources
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#3F4754',
                    }}
                    disableTypography
                    className="mullish weight-400"
                  >
                    Personalized video content tailored to your travel interests
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider sx={{ display: { xs: 'block', md: 'none' } }} />
            </List>
          </Grid>
          <Grid item md={4}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#3F4754',
                    }}
                    disableTypography
                    className="mullish weight-400"
                  >
                    Support for all of your travel planning needs
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#3F4754',
                    }}
                    disableTypography
                    className="mullish weight-400"
                  >
                    Exclusive access to a wealth of travel info
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#3F4754',
                    }}
                    disableTypography
                    className="mullish weight-400"
                  >
                    Flexibility to cancel your subscription at any time
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Divider sx={{ paddingBottom: '10px' }} />
        <Grid
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: '#D1D1E9',
            borderRadius: { xs: '10px', md: '20px' },
            padding: '30px',
            marginTop: '30px',
            marginBottom: '60px',
          }}
        >
          <Box>
            <Typography
              sx={{
                borderRadius: '14px',
                backgroundColor: '#ffffff',
                padding: '4px 0px',
                width: { xs: '60%', md: '40%' },
                textAlign: 'center',
                textTransform: 'uppercase',
                fontSize: '14px',
                lineHeight: '20px',
                whiteSpace: 'nowrap',
              }}
              className="inter-family weight-500"
            >
              Single use Token
            </Typography>
            <Typography
              sx={{
                paddingTop: '10px',
                fontSize: '18px',
                lineHeight: '28px',
              }}
              className="inter-family weight-500"
            >
              Get access to{' '}
              <Box component="span" sx={{ fontWeight: '600' }}>
                one
              </Box>{' '}
              online coaching session for{' '}
              <Box component="span" sx={{ fontWeight: '600' }}>
                $32.99
              </Box>
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: '100%', md: '15%' },
              paddingTop: { xs: '10px', md: '0px' },
            }}
          >
            {subs?.remainingSession === 0 || subs === null ? (
              <Button
                // onClick={() => paymentRef.current.handleOpen()}
                onClick={() => checkSubPrice(false)}
                sx={tokenButton}
                className="mullish weight-700"
              >
                Buy Token
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default Payments;
