import React,{useState} from "react";
import Swal from 'sweetalert2'
import {
  Box,
  FormHelperText,
  Typography,
  Card,
  CardContent,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Alert,
  AlertTitle,
  Grid,
  CircularProgress
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import Api from "../../../../Api";
import { Link, useNavigate } from "react-router-dom";
import NeedsIc from './../../../../images/needs-ic.svg';
import * as Yup from "yup";

const CompleteProfile4 = ({data, setOpenPage, setData}) => {
  const name = "currentNeed";

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isProgress, setIsProgress] = useState(false);

 
  return (
    <Box  sx={{ backgroundColor: "#fff", borderRadius: '30px' ,maxWidth: '900px', width: "95%", margin: '60px auto',}}>
      <Box p={3} sx={{ borderRadius: "10" }}>
      <Grid container >
        <Grid item xs={12} md={2}>
          <Box
            className=""
            component="img"
            sx={{
              height: 90,
              width: 90,
              maxHeight: { xs: 60, md: 90 },
              maxWidth: { xs: 60, md: 90 },
            }}
            alt="Needs icon"
            src={NeedsIc}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography component="h2" variant="h5" gutterBottom>
            What are your current needs?
          </Typography>
          <Typography variant="body1" color="#3B089F">
            Think about the past month and select all needs that have been
            important to you during this time.
          </Typography>
          </Grid>
          </Grid>
          <Formik
            initialValues={data}
            onSubmit={async (values) => {
              setIsProgress(true);
              const response = await Api.post("/customer/current-need", values);
              if (response.data.statusCode === 200) {
                setData(values);
                console.log("success", response);
                Swal.fire({
                    title: "Success",
                    text: "Profile Completed Successfully",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then(function () {
                      window.location.href = "/home";
                    });
                setIsProgress(false);
              } else {
                Swal.fire({
                  title: "Error",
                  text: response.data.errorMsg,
                  icon: "error",
                  confirmButtonText: "OK",
                }).then(function () {
                    // Redirect the user
                    window.location.href = "/home";
                  });
                  setIsProgress(false);
              }
            }}
            validationSchema={Yup.object().shape({
              currentNeed: Yup.string().oneOf(['career_planning_and_growth','role_and_career_success','coping_with_career_obstackles'], "Required").required("Please select any one of the options to proceed")
            })}
          >
            {({ values,errors, touched, handleSubmit, handleChange }) => (
              <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
                <FormControl fullWidth component="fieldset">
                  <RadioGroup
                    name={'currentNeed'}
                    value={values.currentNeed}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="career_planning_and_growth"
                      control={<Radio />}
                      label="Career Planning and Growth"
                    />
                    <FormControlLabel
                      value="role_and_career_success"
                      control={<Radio />}
                      label="Role And Career Success"
                    />
                    <FormControlLabel
                      value="coping_with_career_obstackles"
                      control={<Radio />}
                      label="Coping With Career Obstacles"
                    />
                  </RadioGroup>
                  <ErrorMessage
                        name="currentNeed"
                        render={(msg) => (
                          <FormHelperText
                            error={errors.currentNeed && touched.currentNeed}
                          >
                            {msg}
                          </FormHelperText>
                        )}
                      />
                </FormControl>
                
                <Box mt={4} display="flex" justifyContent="space-between">
                    <Button
                    onClick={() => setOpenPage('third')}
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                >
                    Back
                </Button>
                  <Button
                    onClick={handleSubmit}
                    disabled={isProgress}
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                  >
                    
                    {!isProgress && "Save & Exit"}
                    {isProgress && <CircularProgress />}
                  </Button>
                </Box>
                    </Box>
            )}
          </Formik>
        </Box>
        </Box>
      
  );
};

export default CompleteProfile4;
