import { Modal, Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import Profile from '../Profile';
import { useState } from 'react';
import { useEffect } from 'react';
import Api from '../../../../Api';
import { useNavigate } from 'react-router-dom';
import './style.css';

const DemoHome = () => {
  const [profileData, setProfileData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const navigate = useNavigate();
  const handleProfileModalOpen = () => {
    setOpenProfileModal(true);
  };

  const handleProfileModalClose = () => {
    setOpenProfileModal(false);
  };

  const subscriptionStatus = async () => {
    const response = await Api.get('/customer/subscription');
    setSubscriptionData(response?.data?.subscription);
  };

  useEffect(() => {
    subscriptionStatus();
  }, []);

  const getProfileData = async () => {
    const response = await Api.get('/customer/customer-details');
    if (response.data.statusCode === 200) {
      setProfileData(response?.data?.data);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  console.log('profile data', profileData);

  const handleBooking = () => {
    if (
      profileData?.otherCities.length === 0 ||
      profileData?.languages.length === 0 ||
      profileData?.nativeLanguage === '' ||
      profileData?.city === ''
    ) {
      handleProfileModalOpen();
    } else {
      navigate('/booksession');
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'white',
        width: '100%',
        backgroundImage: {
          xs: "url('/assets/purpleBgMob1.svg'),url('/assets/purpleBgMob2.svg')",
          md: "url('/assets/purpleBg1.svg'),url('/assets/purpleBg2.svg')",
        },
        backgroundRepeat: 'no-repeat,no-repeat',
        backgroundPosition: 'top left,right top',
        // backgroundSize: { xs: "auto", md: "cover" },
        fontFamily: 'Mulish !important',
      }}
      className="home-container"
    >
      <Modal
        open={openProfileModal}
        onClose={handleProfileModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // hideBackdrop={true}
        // onBackdropClick={handleProfileModalClose}
      >
        <Profile handleProfileModalClose={handleProfileModalClose} />
      </Modal>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          paddingY: { xs: '80px', md: '140px' },
          paddingX: { xs: '22px', md: '250px' },
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            fontFamily: "'Playfair Display',serif !important",
            fontWeight: '700',
            fontSize: { xs: '48px', md: '64px' },
            lineHeight: { xs: '55px', md: '90px' },
          }}
        >
          Your next adventure begins here.
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: '500',
            maxWidth: '1049px',
            margin: 'auto',
            fontSize: { xs: '18px', md: '24px' },
            lineHeight: { xs: '25px', md: '30px' },
            paddingTop: { xs: '10px', md: '5px' },
            paddingX: { xs: '5px', md: '0px' },
          }}
          className="mullish"
        >
          Book an online session to explore new cities and cultures from the
          comfort of your own home and start your journey of discovery!
        </Typography>
      </Box>

      <Box
        component="div"
        sx={{
          paddingY: '60px',
          paddingX: { xs: '10px', md: '123px' },
          display: 'flex',
          flexDirection: 'column',
          gap: '25px',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            gap: { xs: '5px', md: '25px' },
            height: '144px',
            paddingX: { xs: '5px', md: '35px' },
            borderRadius: '15px',
            boxShadow:
              '0px 1px 25px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
          onClick={handleBooking}
        >
          <Typography
            sx={{
              // width: { xs: "50%", md: "25%" },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src="/assets/home1.svg"
              alt=""
              className="w-28 max-w-max md:w-auto"
            />
          </Typography>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: { xs: '1px', md: '10px' },
              paddingRight: '10px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: { xs: '15.5px', md: '18px' },
                lineHeight: '20px',
              }}
              className="mullish"
            >
              Make a Booking
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: { xs: '20px', md: '36px' },
                fontWeight: '600',
                color: '#3F4754',
              }}
              className="mullish"
            >
              Book a session with a professional nomad for personalized travel
              planning support
            </Typography>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: 'flex',
            gap: { xs: '5px', md: '25px' },
            height: '144px',
            paddingX: { xs: '5px', md: '35px' },
            borderRadius: '15px',
            boxShadow:
              '0px 1px 25px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
          onClick={() => navigate('/sessionlist')}
        >
          <Typography
            sx={{
              // width: { xs: "50%", md: "25%" },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src="/assets/home2.svg"
              alt=""
              className="w-28 max-w-max md:w-auto"
            />
          </Typography>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: { xs: '1px', md: '10px' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: { xs: '15.5px', md: '18px' },
                lineHeight: '20px',
              }}
              className="mullish"
            >
              Review your coaching session
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: { xs: '20px', md: '36px' },
                fontWeight: '600',
                color: '#3F4754',
              }}
              className="mullish"
            >
              Take some time to share some feedback from your coaching sessions
            </Typography>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: 'flex',
            gap: { xs: '5px', md: '25px' },
            height: '144px',
            paddingX: { xs: '5px', md: '35px' },
            borderRadius: '15px',
            boxShadow:
              '0px 1px 25px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
          onClick={() => navigate('/sessionstatus')}
        >
          <Typography
            sx={{
              // width: { xs: "50%", md: "25%" },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src="/assets/home3.svg"
              alt=""
              className="w-28 max-w-max md:w-auto"
            />
          </Typography>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: { xs: '1px', md: '10px' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: { xs: '15.5px', md: '18px' },
                lineHeight: '20px',
              }}
              className="mullish"
            >
              Your session history
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: { xs: '20px', md: '36px' },
                fontWeight: '600',
                color: '#3F4754',
              }}
              className="mullish"
            >
              View your past and upcoming coaching sessions
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DemoHome;
