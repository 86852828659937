import React, { useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  CircularProgress,
  NativeSelect,
  OutlinedInput
} from "@mui/material";
import { Formik, ErrorMessage } from "formik";
import userfeedback from "../../../images/userfeedback.svg";
import Swal from "sweetalert2";
import Api from "../../../Api";
import * as Yup from "yup";

function Report() {
  const [isProgress, setIsProgress] = useState(false);
  return (
    <Container maxWidth="md" sx={{ marginTop: "50px", marginBottom: "50px" }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography
            variant="h1"
            fontSize={"36px"}
            gutterBottom
            fontWeight={"600"}
          >
            Report a problem
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box
            className=""
            component="img"
            sx={{}}
            alt="Home banner img"
            src={userfeedback}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          reportType: "",
          message: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsProgress(true);
          const response = await Api.post("/customer/report", values);
          if (response.data.statusCode === 200) {
            Swal.fire({
              title: "Success",
              text: "Report Sent Successfully",
              icon: "success",
              confirmButtonText: "OK",
            }).then(function () {
              window.location.href = "/home";
            });
            resetForm({
              reportType: "",
              message: "",
            });
            setIsProgress(false);
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.errorMsg,
              icon: "error",
              confirmButtonText: "OK",
            }).then(function () {
              // Redirect the user
              // window.location.href = "";
            });
            setIsProgress(false);
          }
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string()
            .min(4, "Too Short!")
            .max(300, "Max 300 words allowed!")
            .required("Required"),
            reportType: Yup.string().required("Required"),
        })}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
        }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: "#ffffff",
              marginBottom: "50px",
              borderRadius: "32px",
              padding: "50px !important",
            }}
          >
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <InputLabel required id="reportType">Select Reason</InputLabel>
              <NativeSelect
                required
                labelId="reportType"
                input={<OutlinedInput label="Select Reason" name="reportType" />}
                id="reportType"
                name="reportType"
                value={values.reportType}
                label="Select Reason"
                error={errors.reportType && touched.reportType}
                onChange={handleChange}
                onBlur={handleBlur}
                helpertext={
                  errors.reportType &&
                  touched.reportType &&
                  errors.reportType
                }
              >
                <option value={""} defaultValue disabled>
                  Select option
                </option>
                <option value={"reschedule_session"}>
                  Reschedule Session
                </option>
                <option value={"absent_consultant"}>
                  Consultant Absent
                </option>
                <option value={"session_not_satisfactory"}>
                  Session not satisfactory
                </option>
              </NativeSelect>
              <ErrorMessage
                name="reportType"
                render={(msg) => (
                  <FormHelperText error={true}>{msg}</FormHelperText>
                )}
              />
            </FormControl>
            <TextField
              type="text"
              variant="outlined"
              margin="normal"
              maxLength="300"
              multiline
              rows={4}
              required
              fullWidth
              id="message"
              label="Message"
              name="message"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              error={errors.message && touched.message}
              helperText={`${values.message.length}/${"300"}`}
              InputProps={{
                maxLength: 300,
              }}
            />
            <ErrorMessage
              name="message"
              render={(msg) => (
                <FormHelperText error={errors.message && touched.message}>
                  {msg}
                </FormHelperText>
              )}
            />
            <Box mt={3} align="center">
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isProgress}
                variant="contained"
                sx={{
                  backgroundColor: "#5E17EB",
                  fontSize: "18px",
                  padding: "10px 30px",
                }}
              >
                {!isProgress && "Submit"}
                {isProgress && <CircularProgress />}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </Container>
  );
}

export default Report;
