import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';

import { EmailOutlined, Lock } from '@mui/icons-material';
import LoginLeft from '../images/login-left.svg';
import LoginRight from '../images/login-right.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Api from '../Api';

const Login = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const LoggedIn = token ? true : false;

  const [isProgress, setIsProgress] = useState(false);
  const [cookies, setCookie] = useCookies(['name']);

  const navigateHome = () => {
    if(localStorage.getItem("userType") === "customer"){
      navigate("/home");
    }
    else if (localStorage.getItem("userType") === "consultant"){
      navigate("/consultant/home");
    } else if (localStorage.getItem("userType" === "admin")) {
      navigate("/admin/");
    } else {
      window.location = "https://nomadgrab.com/";
    }
  };

  useEffect(() => {
    if (LoggedIn && localStorage.getItem("userType")) {
      navigateHome();
    }
  }, []);

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl" className="login-main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h4">
            Login to your
          </Typography>
          <Typography component="h2" variant="h6">
            <Link
              href="#"
              sx={{
                color: '#5e17eb',
                textDecoration: 'none',
              }}
            >
              Nomad Grab
            </Link>{' '}
            account
          </Typography>

          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={async (values) => {
              setIsProgress(true);
              const response = await Api.post('/login', values);
              if (response.data.statusCode === 200) {
                localStorage.setItem('userType', response.data.userType);
                localStorage.setItem('token', response.data.token);
                setCookie(
                  'name',
                  response.data.firstName + ' ' + response.data.lastName,
                  { domain: process.env.REACT_APP_COOKIE_URL }
                );
                setCookie('nomad_user_login', true, {
                  domain: process.env.REACT_APP_COOKIE_URL,
                });
                // console.log(cookies, 'cookies445');
                Swal.fire({
                  title: 'Success',
                  text: 'Logged In Successfully',
                  icon: 'success',
                  confirmButtonText: 'OK',
                }).then(function () {
                  if (response.data.userType === 'customer') {
                    navigate('/home');
                  } else if (response.data.userType === 'admin') {
                    navigate('/admin/');
                  } else if (response.data.userType === 'consultant') {
                    navigate('/consultant/home');
                  }
                });
                setIsProgress(false);
              } else {
                Swal.fire({
                  title: 'Error',
                  text: response.data.errorMsg,
                  icon: 'error',
                  confirmButtonText: 'OK',
                });
                setIsProgress(false);
              }
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Invalid email.').required('Required'),
              password: Yup.string().required('Required'),
            })}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
            }) => (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                <TextField
                  type="text"
                  id="email"
                  value={values.email}
                  fullWidth
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  required
                  label="Email Address"
                  name="email"
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="password"
                  id="password"
                  value={values.password}
                  fullWidth
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  required
                  label="Password"
                  name="password"
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isProgress}
                  fullWidth
                  variant="contained"
                  className="bg-theme"
                  size="large"
                  sx={{ mt: 3, backgroundColor: '#5e17eb' }}
                >
                  {!isProgress && 'LOG IN'}
                  {isProgress && <CircularProgress />}
                </Button>
              </Box>
            )}
          </Formik>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ m: 1 }}
          >
            <Grid item>
              New Here?&nbsp;
              <Link
                href="/register"
                underline="none"
                variant="body3"
                sx={{
                  color: '#5e17eb',
                  textDecoration: 'none',
                }}
              >
                Create An Account.
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 5 }}
          >
            <Grid item>
              <Link
                href="/forgotpassword"
                underline="none"
                variant="body3"
                sx={{
                  color: '#5e17eb',
                  textDecoration: 'none',
                  textTransform: 'uppercase',
                }}
              >
                {'Forgot password?'}
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box
          className="login-left"
          component="img"
          sx={{
            height: 250,
            width: 300,
            maxHeight: { xs: 300, md: 200 },
            maxWidth: { xs: 250, md: 250 },
            display: { xs: 'none', sm: 'block' },
          }}
          alt="The house from the offer."
          src={LoginLeft}
        />
        <Box
          className="login-right"
          component="img"
          sx={{
            height: 250,
            width: 350,
            maxHeight: { xs: 200, md: 200 },
            maxWidth: { xs: 300, md: 250 },
          }}
          alt="The house from the offer."
          src={LoginRight}
        />
      </Container>
    </React.Fragment>
  );
};

export default Login;
