import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Box,
  Typography,
  Container,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
  Select,
  InputLabel,
  Stack,
  MenuItem,
  InputAdornment,
  FormHelperText,
  Input,
  Avatar,
  CircularProgress,
  RadioGroup,
  Radio,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material';
import {
  LinkedIn,
  Person,
  Message,
  Public,
  Work,
  Language,
} from '@mui/icons-material';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import { Link } from 'react-router-dom';
import { get_countries } from '../../Customer/redux/master_data';
import Swal from 'sweetalert2';
import Api from '../../../Api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UpdateProfileCons() {
  const INITIAL_FORM_STATE = {
    image: null,
    firstName: '',
    lastName: '',
    jobTitle: '',
    consultantCountries: [],
    backpackerOrExpat: '',
    tellUs: '',
    languages: [],
    linkedInLink: '',
  };

  const FORM_VALIDATION_SCHEMA = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required('Required')
      .min(2, 'First Name must be at least 2 characters.')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    // lastName: Yup.string()
    //   .trim()
    //   .min(2, 'Last Name must be at least 2 characters.')
    //   .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    //   .required('Required'),
    // jobTitle: Yup.string().trim().required('Required'),
    consultantCountries: Yup.array()
      .min(1, 'Select at least one Country')
      .required(),
    backpackerOrExpat: Yup.string()
      .oneOf(['long_term', 'travel_place_to_place'], 'Required')
      .required('Please select any one of the options to proceed'),
    tellUs: Yup.string()
      .trim()
      .min(4, 'Too Short!')
      .max(300, 'Max 300 words allowed!')
      .required('Required'),
    languages: Yup.array().min(1, 'At least one language needed').required(),
    linkedInLink: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .required('Please enter link'),
  });

  const dispatch = useDispatch();

  const [lang, setLang] = useState([]);

  const [isProgress, setIsProgress] = useState(false);

  const [data, setData] = useState();

  const handleData = async () => {
    const response = await Api.get('/consultant/consultant-details');
    const defaultValues = {
      image: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      backpackerOrExpat: '',
      tellUs: '',
      consultantCountries: [],
      languages: [],
      linkedInLink: '',
    };
    setData({ ...defaultValues, ...response.data.data });
    console.log('response.data.data', response.data.data);
  };

  const countries = useSelector((state) => {
    return get_countries(state, dispatch);
  });

  const getLangList = async () => {
    const response = await Api.get('/language-list');
    console.log(response.data.data, 'response');
    setLang([...response.data.data]);
    console.log(lang, 'lang');
  };

  const renderCountry = (selected) => {
    let selectedCountry = [];
    countries.map((name) => {
      if (selected.indexOf(name._id) != -1) {
        selectedCountry.push(name.name);
      }
    });

    return selectedCountry.join(', ');
  };

  const show_image = (values) => {
    if (values.imageSrc) {
      return URL.createObjectURL(values.image);
    } else if (values.image) {
      return process.env.REACT_APP_S3_URL + values.image;
    } else {
      return '';
    }
  };

  useEffect(() => {
    getLangList();
    handleData();
  }, []);

  return (
    <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px' }}>
      <Typography
        variant="h1"
        fontSize={'36px'}
        mb={5}
        gutterBottom
        fontWeight={'600'}
      >
        Update Profile
      </Typography>
      {data && (
        <Formik
          initialValues={data}
          onSubmit={async (values) => {
            setIsProgress(true);
            console.log('values', values);

            let formDataSent = new FormData();
            formDataSent.append('image', values.image);
            formDataSent.append('firstName', values.firstName);
            formDataSent.append('lastName', values.lastName);
            formDataSent.append('jobTitle', values.jobTitle);
            formDataSent.append('backpackerOrExpat', values.backpackerOrExpat);
            formDataSent.append('tellUs', values.tellUs);
            formDataSent.append(
              'consultantCountries',
              JSON.stringify(values.consultantCountries)
            );
            formDataSent.append('languages', JSON.stringify(values.languages));
            formDataSent.append('linkedInLink', values.linkedInLink);
            const response = await Api.post(
              '/consultant/update-profile',
              formDataSent
            );

            if (response.data.statusCode === 200) {
              setData(formDataSent);
              console.log(data, 'datall');
              Swal.fire({
                title: 'Success',
                text: 'Profile Completed Successfully',
                icon: 'success',
                confirmButtonText: 'OK',
              }).then(function () {
                // Redirect the user
                window.location.href = '/consultant/home';
              });
              setIsProgress(false);
            } else {
              Swal.fire({
                title: 'Error',
                text: response.data.errorMsg,
                icon: 'error',
                confirmButtonText: 'OK',
              });
              setIsProgress(false);
            }
          }}
          validationSchema={FORM_VALIDATION_SCHEMA}
        >
          {(props) => {
            const {
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              setFieldValue,
            } = props;

            console.log('values', values);
            return (
              <Box
                component="form"
                onSubmit={handleSubmit}
                className="md-p-20"
                sx={{
                  backgroundColor: '#ffffff',
                  marginBottom: '50px',
                  borderRadius: '32px',
                  padding: '50px !important',
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    padding: '8.5px 14px',
                    mb: 2,
                    position: 'relative',
                  }}
                >
                  <Avatar
                    sx={{ height: '200px', width: '200px' }}
                    component="span"
                    src={show_image(values)}
                    alt={values.image ? values.image.name : ''}
                  >
                    {!values.image && (
                      <Person sx={{ height: '150px', width: '150px' }} />
                    )}
                  </Avatar>

                  <Input
                    accept="image/*"
                    type="file"
                    name="image"
                    id="image"
                    style={{
                      display: 'none',
                      position: 'relative',
                    }}
                    onBlur={handleBlur}
                    error={errors.image && touched.image}
                    helperText={errors.image && touched.image && errors.image}
                    onChange={(e) => {
                      if (
                        (e.target.files[0].type === 'image/png',
                        'image/jpg',
                        'image/jpeg')
                      ) {
                        setFieldValue('image', e.target.files[0]);
                        setFieldValue('imageSrc', e.target.files[0]);
                      }
                    }}
                  />
                  <InputLabel
                    htmlFor="image"
                    style={{
                      position: 'absolute',
                      right: '40%',
                      bottom: '10px',
                      border: '1px solid #bdbdbd00',
                      lineHeight: '0',
                      padding: '8px',
                      borderRadius: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <CameraAltRoundedIcon />
                  </InputLabel>

                  <ErrorMessage
                    name="image"
                    render={(msg) => (
                      <FormHelperText error={errors.image && touched.image}>
                        {msg}
                      </FormHelperText>
                    )}
                  />
                </Stack>

                <TextField
                  fullWidth
                  type="text"
                  id="firstName"
                  required
                  value={values.firstName}
                  autoComplete="off"
                  margin="normal"
                  label="First Name"
                  name="firstName"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  error={errors.firstName && touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  fullWidth
                  id="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  margin="normal"
                  label="Last Name"
                  name="lastName"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName && errors.lastName}
                  helperText={
                    errors.lastName && touched.lastName && errors.lastName
                  }
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  id="jobTitle"
                  margin="normal"
                  label="Job Title"
                  name="jobTitle"
                  value={values.jobTitle}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  error={errors.jobTitle && touched.jobTitle && errors.jobTitle}
                  helperText={
                    errors.jobTitle && touched.jobTitle && errors.jobTitle
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Work />
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControl margin="normal" fullWidth component="fieldset">
                  <FormLabel id="backpackerOrExpat">Backpacker/Expat</FormLabel>
                  <RadioGroup
                    aria-labelledby="backpackerOrExpat"
                    name={'backpackerOrExpat'}
                    value={values.backpackerOrExpat}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="long_term"
                      control={<Radio />}
                      label="Are you living in a place long term ?"
                    />
                    <FormControlLabel
                      value="travel_place_to_place"
                      control={<Radio />}
                      label="Do you travel from place to place ?"
                    />
                  </RadioGroup>
                  <ErrorMessage
                    name="backpackerOrExpat"
                    render={(msg) => (
                      <FormHelperText
                        error={
                          errors.backpackerOrExpat && touched.backpackerOrExpat
                        }
                      >
                        {msg}
                      </FormHelperText>
                    )}
                  />
                </FormControl>

                <TextField
                  type="text"
                  variant="outlined"
                  margin="normal"
                  maxLength="300"
                  multiline
                  required
                  rows={4}
                  fullWidth
                  id="tellUs"
                  label="Tell Us About Yourself"
                  name="tellUs"
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tellUs}
                  error={errors.tellUs && touched.tellUs}
                  helperText={`${values?.tellUs?.length}/${'300'}`}
                  InputProps={{
                    maxLength: 300,
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: 'flex',
                          flexDirection: 'column-reverse',
                        }}
                        position="start"
                      >
                        <Message />
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="tellUs"
                  render={(msg) => (
                    <FormHelperText error={errors.tellUs && touched.tellUs}>
                      {msg}
                    </FormHelperText>
                  )}
                />

                <FormControl margin="normal" fullWidth sx={{ mb: 3 }}>
                  <InputLabel
                    id="consultantCountries"
                    required
                    error={
                      errors.consultantCountries && touched.consultantCountries
                    }
                  >
                    Countries Lived In
                  </InputLabel>
                  <Select
                    variant="outlined"
                    label="Countries Lived In"
                    id="consultantCountries"
                    margin="normal"
                    required
                    value={values.consultantCountries}
                    name="consultantCountries"
                    multiple
                    input={
                      <OutlinedInput
                        label="Countries Lived In"
                        name="consultantCountries"
                      />
                    }
                    renderValue={(selected) => renderCountry(selected)}
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    error={
                      errors.consultantCountries && touched.consultantCountries
                    }
                    helperText={
                      touched.consultantCountries && errors.consultantCountries
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <Public />
                      </InputAdornment>
                    }
                  >
                    {countries?.map((country) => {
                      return (
                        <MenuItem
                          component="li"
                          value={country._id}
                          key={country._id}
                        >
                          <Checkbox
                            checked={
                              values?.consultantCountries?.indexOf(
                                country._id
                              ) > -1
                            }
                          />
                          <ListItemText primary={country.name} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    name="consultantCountries"
                    render={(msg) => (
                      <FormHelperText
                        error={
                          errors.consultantCountries &&
                          touched.consultantCountries
                        }
                      >
                        {msg}
                      </FormHelperText>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel id="languages">Languages Spoken</InputLabel>
                  <Select
                    name="languages"
                    labelId="languages"
                    id="languages"
                    label="languages"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiple
                    required
                    input={
                      <OutlinedInput
                        label="Languages Spoken"
                        name="languages"
                      />
                    }
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    onChange={(e) => [
                      setFieldValue('languages', e.target.value),
                      (e) => handleChange(e),
                    ]}
                    onBlur={handleBlur}
                    value={values.languages}
                    error={errors.languages && touched.languages}
                    helperText={
                      touched.languages && errors.languages && touched.languages
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <Language />
                      </InputAdornment>
                    }
                  >
                    {lang?.map((language) => {
                      return (
                        <MenuItem
                          key={language.langName}
                          value={language.langName}
                        >
                          <Checkbox
                            checked={
                              values?.languages?.indexOf(language.langName) > -1
                            }
                          />
                          <ListItemText primary={language.langName} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    name="languages"
                    render={(msg) => (
                      <FormHelperText error={true}>{msg}</FormHelperText>
                    )}
                  />
                </FormControl>

                <TextField
                  type="text"
                  fullWidth
                  id="linkedInLink"
                  value={values.linkedInLink}
                  autoComplete="off"
                  margin="normal"
                  required
                  label="Linkedin Profile Link"
                  name="linkedInLink"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  error={
                    errors.linkedInLink &&
                    touched.linkedInLink &&
                    errors.linkedInLink
                  }
                  helperText={
                    errors.linkedInLink &&
                    touched.linkedInLink &&
                    errors.linkedInLink
                  }
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedIn />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  textAlign="center"
                  sx={{ display: { xs: 'block', sm: 'flex' } }}
                >
                  <Link
                    style={{ marginTop: '4%' }}
                    to="/consultant/changepassword"
                    className="md-w-100"
                  >
                    Change Password
                  </Link>

                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className="md-w-100"
                    sx={{
                      mt: 3,
                      border: '1px solid #5e17eb',
                      backgroundColor: '#5e17eb',
                    }}
                  >
                    {!isProgress && 'Submit'}
                    {isProgress && <CircularProgress />}
                  </Button>
                </Box>
              </Box>
            );
          }}
        </Formik>
      )}
    </Container>
  );
}

export default UpdateProfileCons;
