import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import Api from "../../../Api";
import {
  Button,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  NativeSelect,
  Container,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
  , Stack, Paper
} from "@mui/material";
import SquareIcon from '@mui/icons-material/Square';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled } from '@mui/material/styles';
import Swal from "sweetalert2";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AvailableSlots = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chooseDate, setChooseDate] = useState(new Date());
  const [slotsName, setSlotsName] = useState([
                        {name:"Morning", startTime: 8, endTime: 12, time:"08:00 AM - 12:00 AM", status:"", sloatStatus:""},
                        {name:"Afternoon", startTime: 12, endTime: 16, time:"12:00 PM - 4:00 PM", status:"", sloatStatus:""},
                        {name:"Evening", startTime: 16, endTime: 20, time:"04:00 PM - 08:00 PM", status:"", sloatStatus:""}
                      ]);
  const todayDate = useMemo(() => {
    return moment().startOf('date').toDate();
  });

  const [calender, setCalender] = useState([]);

  const getWeek = (date) => {
    var weekDays = moment(date).startOf('week');

    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(weekDays.toDate());
      weekDays.add(1, 'days');
    }
    setCalender([...week]);
  }

  const nextWeek = () => {
    const selectedDate2 = moment(selectedDate).add(7, 'days').toDate();
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  }

  const prevWeek = () => {
    const selectedDate2 = moment(selectedDate).add(-7, 'days').toDate();
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  }

  const clickDate = async(date) => {
    setChooseDate(date);
    
    const startTime = new Date(date);
    startTime.setHours(0, 0, 0);
    const endTime = new Date(date);
    endTime.setHours(23, 59, 59);
    
    const res = await Api.post('/consultant/slot-availability',{startTime: startTime, endTime: endTime});
    for (const j in slotsName){
      slotsName[j].status = "";
      slotsName[j].sloatStatus = "";
      delete slotsName[j]._id;
      for(const slot of res.data.data){
        if(slot.slotType === slotsName[j].name.toLowerCase()){
          slotsName[j].status = slot.status;
          slotsName[j]._id = slot._id;
        }
      }

      const sloatStartTime = new Date(date);
      sloatStartTime.setHours(slotsName[j].startTime, 0, 0);
      const sloatEndTime = new Date(date);
      sloatEndTime.setHours(slotsName[j].endTime, 0, 0);

      for(const slot of res.data.sessions){
        const dateTimeLocal = new Date(slot.dateTime);
        if(sloatStartTime < dateTimeLocal && sloatEndTime > dateTimeLocal){
          slotsName[j].sloatStatus = "busy";
        }
      }
    }

    console.log("slotsName", slotsName)

    setSlotsName([...slotsName])
  }

  const clickTimeSlot = async(row, index) => {
    if(row.sloatStatus === "busy" && row._id){
      Swal.fire({
        title: "Error",
        text: "Someone booked any session in this time slot.",
        icon: "error",
        confirmButtonText: "OK",
      })
    }
    else{
      const startTime = new Date(chooseDate);
      startTime.setHours(row.startTime, 0, 0);

      if(new Date() > startTime){
        Swal.fire({
          title: "Error",
          text: "You can not modify old time slot.",
          icon: "error",
          confirmButtonText: "OK",
        })
      }
      else if(row._id){
        const res = await Api.post('/consultant/delete-availability',{_id:row._id});
        delete slotsName[index]._id;
        slotsName[index].status = "";
        slotsName[index].sloatStatus = "";
        setSlotsName([...slotsName]);
      }
      else{
        const endTime = new Date(chooseDate);
        endTime.setHours(row.endTime, 0, 0);

        const res = await Api.post('/consultant/add-availability',{slotType:slotsName[index].name.toLowerCase() ,startTime: startTime, endTime: endTime});
        
        if(res.data.statusCode == 200){
          slotsName[index]._id = res.data.data._id;
          slotsName[index].status = "active";
          setSlotsName([...slotsName]);
        }
      }
    }
  }

  const showWeekMonth = () => {
    var weekDays = moment(selectedDate).startOf('week').toDate();
    const lastDateOfMonth = moment(weekDays).add(6,'days').toDate();
    if(weekDays.getMonth() == lastDateOfMonth.getMonth()){
      return moment(weekDays).format("MMMM") + "  " +moment(selectedDate).format('Y')
    }
    else{
      return moment(weekDays).format("MMM")+" - "+moment(lastDateOfMonth).format("MMM")+ "  " +moment(selectedDate).format('Y')
    }
    
  }

  useEffect(() => {
    getWeek(selectedDate);
    clickDate(new Date());
  }, []);

  return (<>
    <Container maxWidth="lg">
      <CssBaseline />
      <Typography mt={4} mb={3} textAlign={"center"} component="h1" variant="h4">
        Your scheduled slots
      </Typography>
      <Typography textAlign={"center"} component="h2" variant="h6">
        Session are scheduled for 30 minutes, but can be as short as you’d like.
      </Typography>
      <Box className="md-p-20" margin={'50px auto'} backgroundColor={"#ffffff"} padding={"50px"} borderRadius={"10px"}>
        <Grid item textAlign='center' mb={2} xs={12} sx={{ border: "1px solid #9F9F9F", borderRadius: "5px" }}>
          <CalendarMonthIcon sx={{'margin':'10px'}} fontSize='large'/>
          <Box mb={2} >{showWeekMonth()}</Box>
          <Box mb={3} backgroundColor={"#ffffff"} borderRadius={"10px"}>
            <Box mb={3}>
              <Button  type="button" onClick={prevWeek} disabled={new Date() > selectedDate}><ArrowBackIosNewIcon/></Button>
              {calender.map((date, i) => (
                <Button 
                  name="date"
                  type="button" 
                  key={i}
                  onClick={() => clickDate(date)} 
                  disabled={todayDate > date} 
                  variant={moment(chooseDate).format("MM-D-YYYY") == moment(date).format("MM-D-YYYY")?"contained":"outlined"}
                >
                  {moment(date).format("ddd")} <br />
                  {moment(date).format("D")}
                </Button>
              ))}
              <Button type="button" onClick={nextWeek}><ArrowForwardIosIcon/></Button>
            </Box>
          </Box>
        </Grid> 

        <Typography component="h2" variant="h6" fontSize={"24px"} fontWeight={'500'} mb={2}>
          Book slot
        </Typography>
        <Grid
          container
          spacing={0}
          sx={{ border: "1px solid #9F9F9F", borderRadius: "5px" }}
          mb={5}
        >
           {slotsName.map((slot, index) => (
          <Grid item xs={12} sm={4} p={2}>
            <Typography
              variant="h6"
              textAlign={"center"}
              component="h6"
            >
              {slot.name}
            </Typography>
            <Typography
              variant="body2"
              textAlign={"center"}
              backgroundColor={slot.status==="active"?"#30AA05B2":"#D9D9D9"}
              // color={"#165300"}
              p={2}
              mt={2}
              mb={2}
              gutterBottom
              fontSize={"18px"}
              sx={{ "cursor": "pointer" }}
              onClick={() => clickTimeSlot(slot, index)}
            >
              {slot.time}
            </Typography>
          </Grid>))}
        </Grid>
        <Stack direction="row" spacing={2} mb={5}>
          <Typography><SquareIcon fontSize="22" style={{ color: "#D9D9D9" }} /> Not Available</Typography>
          {/* <Typography><SquareIcon fontSize="22" style={{ color: "#E47373" }} /> Not avaible</Typography> */}
          <Typography><SquareIcon fontSize="22" style={{ color: "#63B845" }} /> Available</Typography>
        </Stack>
        {/* <Typography textAlign={"center"} variant="body1" >
          <strong>Don’t see good time?</strong>{" "}
          <Link href="#" sx={{ color: "#5e17eb", textDecoration: "none" }}>
            Edit your preferences
          </Link>{" "}
          to see other availability.
        </Typography> */}
      </Box>

    </Container>
  </>
  )
}


export default AvailableSlots;