import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CardMedia,
  Container,
  TablePagination,
  Grid,
} from '@mui/material';
import moment from 'moment';
import sessionclock from '../../../images/watch.svg';
import failed from './../../../images/cancel.svg';
import success from './../../../images/success.svg';
import upcomming from './../../../images/upcomming.svg';
import Api from '../../../Api';
import Swal from 'sweetalert2';

const SessionsStatus = () => {
  const [session, setSession] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState('upcoming');

  const getSessionList = async (page = 1) => {
    setPage(page);

    const response = await Api.get(
      `/customer/session-list?page=${page}&type=${status}`
    );
    setSession([...response.data.data.docs]);
    setTotalCount(response.data.data.total);
  };

  const handleChangePage = (event = null, newPage = 1) => {
    getSessionList(newPage + 1);
  };

  const checkDeleteButton = (item) => {
    const join_start_time = moment().add(+240, 'minutes').toDate();

    let show_btn = true;
    if (item.status !== 'scheduled') {
      show_btn = false;
    } else if (new Date(item.dateTime) < join_start_time) {
      show_btn = false;
    }

    return show_btn;
  };

  const checkJoinButton = (item) => {
    const join_start_time = moment().add(+5, 'minutes').toDate();
    const join_end_time = moment().add(-60, 'minutes').toDate();

    let show_btn = false;
    if (
      new Date(item.dateTime) < join_start_time &&
      new Date(item.dateTime) > join_end_time
    ) {
      show_btn = true;
    }

    return show_btn;
  };

  const cancelSession = async (item) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: "No, don't cancel !",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire('Cancelled!', 'Your session has been cancelled.', 'success');
        await Api.post('/customer/cancel-session', { _id: item._id });
        getSessionList(page);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancel Revoked', 'Session not cancelled', 'error');
      }
    });
  };

  useEffect(() => {
    getSessionList(1);
  }, [status]);

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Box>
        <Box
          className=""
          component="img"
          sx={{
            height: 90,
            width: 90,
            maxHeight: { xs: 60, md: 90 },
            maxWidth: { xs: 60, md: 90 },
          }}
          alt="Needs icon"
          src={sessionclock}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pl: 3,
            pt: 3,
          }}
        >
          <Typography component="h2" variant="h5" gutterBottom>
            Your {status} sessions
          </Typography>
          <Button
            onClick={() =>
              setStatus(status === 'upcoming' ? 'past' : 'upcoming')
            }
          >
            View {status === 'upcoming' ? 'past' : 'upcoming'} Session
          </Button>
        </Box>
      </Box>
      {session.length > 0 ? (
        session.map((item) => {
          let dateTimeAfterOneHour = new Date(item.dateTime);
          dateTimeAfterOneHour.setHours(dateTimeAfterOneHour.getHours() + 1);

          let status = 'Completed session';
          let imageName = success;
          if (
            item.status === 'cancelledByConsultant' ||
            item.status === 'cancelledByCustomer'
          ) {
            status = 'Cancelled session';
            imageName = failed;
          } else if (
            item.status === 'scheduled' &&
            new Date(dateTimeAfterOneHour) >= new Date()
          ) {
            status = 'Upcomming session';
            imageName = upcomming;
          } else if (
            item.status === 'scheduled' &&
            new Date(dateTimeAfterOneHour) < new Date()
          ) {
            status = 'Session Missed';
            imageName = failed;
          }

          return (
            <Card
              sx={{ display: 'flex', pl: 2, m: 2, width: '100%' }}
              key={item._id}
            >
              <CardMedia
                component="img"
                sx={{
                  height: 90,
                  width: 90,
                  maxHeight: { xs: 60, md: 90 },
                  maxWidth: { xs: 60, md: 100 },
                  p: 2,
                  justifyContent: 'space-between',
                }}
                alt={status}
                src={imageName}
              />
              <CardContent
                sx={{
                  borderLeft: 1,
                  borderColor: '#C8C8C8',
                  display: { xs: 'block', sm: 'flex' },
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box>
                  <Typography component="div" variant="h5" value={item._id}>
                    {moment(item.dateTime).format('ddd, MMM D')} at{' '}
                    {moment(item.dateTime).format('hh:mm A')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#5E17EB"
                    value={item._id}
                  >
                    {status}
                  </Typography>
                </Box>
                <Box>
                  <Grid container sx={{ display: 'block' }}>
                    <Grid item xs={12}>
                      {checkDeleteButton(item) && (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => cancelSession(item)}
                        >
                          Cancel
                        </Button>
                      )}
                      {checkJoinButton(item) && (
                        <Button
                          variant="contained"
                          sx={{
                            border: '1px solid #5e17eb',
                            backgroundColor: '#5e17eb',
                            alignContent: 'right',
                          }}
                          href={item.customerZoomLink}
                          target="_blank"
                        >
                          Join
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Typography
          m={2}
          component="div"
          sx={{ textAlign: 'center' }}
          variant="h5"
        >
          No sessions to display
        </Typography>
      )}
      {session.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={10}
          page={page - 1}
          onPageChange={handleChangePage}
        />
      )}
    </Container>
  );
};

export default SessionsStatus;
