import React, {useRef,useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, CssBaseline, Box, Typography, Container} from '@mui/material';
import Swal from 'sweetalert2'
import HomeImg from '../../../images/home-img.svg'
import CalendarImg from '../../../images/cal_plus.svg'
import ReviewsImg from '../../../images/reviews.svg'
import ProfileImg from '../../../images/profile.svg'
import reportImg from '../../../images/report.svg'
import success from "../../../images/success.svg";
import Payment from "./Payment";
import moment from 'moment'
import Api from "../../../Api";

const theme = createTheme();

const Home = () => {
    const [subsMsg, setSubsMsg] = useState('')
    const [status, setStatus] = useState('')
    const [subs, setSubs] = useState(null)

    const paymentRef = useRef();

    const onPaymentComplete = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                title: "Success",
                text: "Payment Successfull!!",
                icon: "success",
                confirmButtonText: "OK",
            }).then(function () {
                window.location.reload();
            });
        }
        else{
            Swal.fire({
                title: "Error",
                text: response.data.errorMsg,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }

    const subscriptionStatus =async()=>{
        const response = await Api.get("/customer/subscription")
        setSubs(response.data.subscription)
        const endSubsDate = new Date(response?.data?.subscription?.endDate)

        const date1 = moment(endSubsDate)
        const date2 = moment(new Date());
        const dateDiff = date1.diff(date2, 'days') + 1

        if (dateDiff< 4 && dateDiff > 0 ){
            setStatus('leftOver')
            return setSubsMsg(<Typography color={"error"} sx={{fontWeight:'500'}}>Your subscription is about to expire on  {moment(date1).format("MMM Do YYYY") } please recharge. Days left {dateDiff}. </Typography>)
        }
        else if(dateDiff > 4  ){
            setStatus('active')
            return setSubsMsg(<Typography sx={{fontWeight:'500'}}>You have active subscription until {moment(date1).format("MMM Do YYYY")}.</Typography>)
        }
        else if(subs === null){
            setStatus('noSubs')
            return setSubsMsg(<Typography color={"error"}  sx={{fontWeight:'500'}}>You have not subscribed to any plan yet. Please subscribe to avail services.</Typography>)
        }
        else{
            setStatus('expired')
            return setSubsMsg(<Typography color={"error"} sx={{fontWeight:'500'}}>Your plan has been expired. Please recharge to avail services.</Typography>)
        }
    }

    useEffect(() => {
        subscriptionStatus()
    }, [])

    return (
        <React.Fragment>
            <Payment ref={paymentRef} onPaymentComplete={onPaymentComplete} />
            <ThemeProvider theme={theme}>
                <Box className="home-banner theme-padding">
                    <Container>
                        <Box className="home-banner-inner">
                            <Box className="home-banner-col">
                            <Typography variant="h1" component="h5" gutterBottom fontWeight={700} fontSize={"35px"} color="#ffffff">Go on an adventure with a worldwide group of nomads. Book an online session to learn about a new city.</Typography>
                                  {(status ==='expired' || status==='leftOver' || status === 'noSubs') &&
                                        <Button
                                            variant="contained"
                                            size="large"
                                            sx={{ mt: 3, backgroundColor: "#fff", color: "#000000" }}
                                            onClick={() => paymentRef.current.handleOpen()}
                                            >
                                            Pay $60
                                        </Button> 
                                    }
                            </Box>
                            
                            <Box className="home-banner-col">
                                <Box
                                    className=''
                                    component="img"
                                    sx={{

                                    }}
                                    alt="Home banner img"
                                    src={HomeImg}
                                />
                            </Box>
                        </Box>
                            <Typography>{subsMsg}</Typography>
                    </Container>
                </Box>
                <Container>
                    <Box className="action-section">
                        <Box className="action-section-inner">
                            <Box className="action-item">
                                <Box className="action-img">
                                    <Link to={status === "active"||status ==="leftOver"?"/booksession":""} style={{"cursor": status === "active"||status === "leftOver"?"pointer":"not-allowed"}}>
                                        <Box
                                            className=''
                                            component="img"
                                            sx={{

                                            }}
                                            alt="calendar image"
                                            src={CalendarImg}
                                        />
                                    </Link>
                                </Box>
                                
                                <Box className="action-title">
                                    <Link to={status === "active"||status === "leftOver"?"/booksession":""} style={{"cursor": status === "active"||status === "leftOver"?"pointer":"not-allowed", "textDecoration": "none", "color": "black"}}>
                                    <Typography variant="h6" component="h2" fontWeight="700" gutterBottom>Book your next session</Typography>
                                        <Typography variant="subtitle2" gutterBottom>Chat with the same Pro as last time, or pick someone new.</Typography>
                                    </Link>
                                </Box>
                            </Box>
                            <Box className="action-item">
                                <Box className="action-img">
                                <Link to={status === "active"||status === "leftOver"?"/profile":""} style={{"cursor": status === "active"||status === "leftOver"?"pointer":"not-allowed"}}>
                                    <Box
                                        className=''
                                        component="img"
                                        sx={{

                                        }}
                                        alt="calendar image"
                                        src={ProfileImg}
                                    />
                                </Link>
                                </Box>
                                <Box className="action-title">
                                <Link to={status === "active"||status === "leftOver"?"/profile":""} style={{"cursor": status === "active"||status === "leftOver"?"pointer":"not-allowed","textDecoration": "none", "color": "black"}}>
                                <Typography variant="h6" component="h2" fontWeight="700" gutterBottom>Complete your profile</Typography>
                                        <Typography variant="subtitle2" gutterBottom>Finish completing your profile.</Typography>
                                </Link>
                                </Box>
                            </Box>
                            <Box className="action-item">
                                <Box className="action-img">
                                <Link to={status ==="active"|| status === "leftOver"?"/sessionlist":""} style={{"cursor": status === "active"||status ==="leftOver"?"pointer":"not-allowed"}}>
                                    <Box
                                        className=''
                                        component="img"
                                        sx={{

                                        }}
                                        alt="calendar image"
                                        src={ReviewsImg}
                                    />
                                </Link>
                                </Box>
                                <Box className="action-title">
                                <Link to={status ==="active"|| status ==="leftOver"?"/sessionlist":""} style={{"cursor": status === "active"|| status ==="leftOver"?"pointer":"not-allowed","textDecoration": "none", "color": "black"}}>
                                <Typography variant="h6" component="h2" fontWeight="700" gutterBottom>Review your coaching session</Typography>
                                   <Typography variant="subtitle2" gutterBottom>Share your feedback from your sessions.</Typography>
                                </Link>
                                </Box>
                            </Box>
                            <Box className="action-item">
                                <Box className="action-img">
                                <Link to={status === "active"||status ==="leftOver"?"/sessionstatus":""} style={{"cursor": status === "active"||status ==="leftOver"?"pointer":"not-allowed"}}>
                                    <Box
                                        className=''
                                        component="img"
                                        sx={{
                                           

                                        }}
                                        alt="calendar image"
                                        src={success}
                                    />
                                    </Link>
                                </Box>
                                <Box className="action-title">
                                <Link to={status === "active"|| status ==="leftOver"?"/sessionstatus":""} style={{"cursor": status === "active"|| status ==="leftOver"?"pointer":"not-allowed","textDecoration": "none", "color": "black"}}>
                                <Typography variant="h6" component="h2" fontWeight="700" gutterBottom>Your sessions</Typography>
                                    <Typography variant="subtitle2" gutterBottom>See your past and upcoming session details.</Typography>
                                </Link>
                                </Box>
                            </Box>
                            <Box className="action-item">
                                <Box className="action-img">
                                <Link to={status === "active"||status ==="leftOver"?"/report":""} style={{"cursor": status === "active"|| status ==="leftOver"?"pointer":"not-allowed"}}>
                                    <Box
                                        className=''
                                        component="img"
                                        sx={{
                                            height:'70px'
                                        }}
                                        alt="calendar image"
                                        src={reportImg}
                                    />
                                    </Link>
                                </Box>
                                <Box className="action-title">
                                <Link to={status === "active"|| status ==="leftOver"?"/report":""} style={{"cursor": status === "active"||status ==="leftOver"?"pointer":"not-allowed","textDecoration": "none", "color": "black"}}>
                                <Typography variant="h6" component="h2" fontWeight="700" gutterBottom>Report a Problem</Typography>
                                </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container component="div" maxWidth="xl">
                    <CssBaseline />
                </Container>
            </ThemeProvider>
        </React.Fragment >

    )
}

export default Home
