import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../images/logo.svg';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PropTypes from 'prop-types';
import Api from '../Api';
import { useCookies } from 'react-cookie';

import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import PaymentModal from '../Front/Customer/Views/PaymentModal';
import { useDispatch } from 'react-redux';
import './style.css';

const Header = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['name']);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');
  const LoggedIn = token ? true : false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };

  const handlePaymentModalOpen = () => {
    dispatch({ type: 'SHOW_MODAL' });
  };

  const ListItemTextStyle = {
    paddingX: '4px',
    fontSize: '14px',
    lineHeight: '25px',
    fontWeight: '600',
    fontFamily: "'Mulish', sans-serif !important",
  };

  const MenuItemStyle = {
    paddingX: '4px',
    paddingY: '10px',
    fontWeight: '600',
    fontSize: { xs: '14px', md: '16px' },
    lineHeight: { xs: '20px', md: '25px' },
  };

  const handleLogout = () => {
    localStorage.clear();
    if (cookies) {
      removeCookie('name');
      setCookie('nomad_user_login', false, {
        domain: process.env.REACT_APP_COOKIE_URL,
      });
    }
    window.location.href = process.env.REACT_APP_HOME_LINK;
    // navigate('/login');
  };

  const navigateAndCloseDropdown = (value) => {
    handleClose();
    navigate(value);
  };

  const subscriptionStatus = async () => {
    const response = await Api.get('/customer/subscription');
    setSubscriptionData(response?.data?.subscription);
  };

  useEffect(() => {
    if (LoggedIn) {
      subscriptionStatus();
    }
  }, []);

  const navigateHome = () => {
    if (localStorage.getItem('userType') === 'customer') {
      navigate('/home');
    } else if (localStorage.getItem('userType') === 'consultant') {
      navigate('/consultant/home');
    } else if (localStorage.getItem('userType' === 'admin')) {
      navigate('/admin/');
    } else {
      // navigate("/");
      window.location = 'https://nomadgrab.com/';
    }
  };

  return (
    <Box>
      <PaymentModal />
      <Drawer
        variant="temporary"
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer}
        PaperProps={{
          sx: { width: '100%' },
        }}
      >
        <Box
          sx={{ position: 'relative', height: '100vh', paddingTop: '10px' }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, cursor: 'pointer' }}
              onClick={navigateHome}
            >
              <img src={Logo} alt="logo" />
            </Typography>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <List sx={{ paddingLeft: '12px', paddingTop: '24px' }}>
            {LoggedIn ? (
              <>
                <ListItem
                  onClick={() =>
                    navigate(
                      userType === 'customer'
                        ? '/profile'
                        : '/consultant/updateprofilecons'
                    )
                  }
                >
                  <ListItemText
                    disableTypography
                    sx={ListItemTextStyle}
                    className="mullish"
                  >
                    Profile
                  </ListItemText>
                </ListItem>
                <ListItem
                  onClick={() =>
                    navigate(
                      userType === 'customer'
                        ? '/sessionstatus'
                        : '/consultant/upcomingSessions'
                    )
                  }
                >
                  <ListItemText
                    disableTypography
                    sx={ListItemTextStyle}
                    className="mullish"
                  >
                    Upcoming Appointments
                  </ListItemText>
                </ListItem>
                {userType === 'customer' ? (
                  <ListItem onClick={() => navigate('/report')}>
                    <ListItemText
                      disableTypography
                      sx={ListItemTextStyle}
                      className="mullish"
                    >
                      Report a Problem
                    </ListItemText>
                  </ListItem>
                ) : null}
                {/* <ListItem>
                  <ListItemText disableTypography sx={ListItemTextStyle} className="mullish">Membership</ListItemText>
                </ListItem> */}
                {/*<ListItem>*/}
                {/*  <ListItemText sx={ListItemTextStyle} className="mullish">*/}
                {/*    Payment methods*/}
                {/*  </ListItemText>*/}
                {/*</ListItem>*/}
                {/*<ListItem>*/}
                {/*  <ListItemText sx={ListItemTextStyle} className="mullish">Refer a Friend</ListItemText>*/}
                {/*</ListItem>*/}
              </>
            ) : null}
            <ListItem>
              <ListItemText
                disableTypography
                sx={ListItemTextStyle}
                className="mullish"
                onClick={() => navigate('/help')}
              >
                Help & Support
              </ListItemText>
            </ListItem>
            {LoggedIn ? (
              <ListItem>
                <ListItemText
                  disableTypography
                  sx={ListItemTextStyle}
                  onClick={handleLogout}
                  className="mullish"
                >
                  Logout
                </ListItemText>
              </ListItem>
            ) : (
              <ListItem>
                <ListItemText
                  disableTypography
                  sx={ListItemTextStyle}
                  onClick={() => navigate('/login')}
                  className="mullish"
                >
                  Login
                </ListItemText>
              </ListItem>
            )}
          </List>

          {subscriptionData?.remainingSession === 0 || !subscriptionData ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: '25px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  paddingX: '2px',
                  fontSize: '14px',
                  lineHeight: { xs: '15px', md: '20px' },
                  // textAlign: "center",
                }}
                className="mullish weight-500"
              >
                You haven’t sign up for our membership plan yet.
              </Typography>
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: { xs: '15px', md: '20px' },
                  textDecoration: 'underline',
                  // textAlign: "center",
                }}
                className="mullish weight-700"
              >
                Learn more.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Drawer>
      {subscriptionData?.remainingSession === 0 || !subscriptionData ? (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography
            sx={{
              textAlign: 'center',
              transform: 'translateY(10px)',
              fontSize: '14px',
              lineHeight: { xs: '15px', md: '20px' },
            }}
            className="mullish weight-500"
          >
            You haven’t sign up for our membership plan yet.{' '}
            <Box
              component="span"
              sx={{ borderBottom: '1px solid black', cursor: 'pointer' }}
              onClick={handlePaymentModalOpen}
              className="mullish weight-700"
            >
              Learn more
            </Box>
          </Typography>
        </Box>
      ) : null}
      <Toolbar disableGutters sx={{ paddingX: { xs: '20px', md: '50px' } }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img
            src={Logo}
            alt="logo"
            onClick={navigateHome}
            className="cursor-pointer"
          />
        </Typography>

        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Button
            sx={{
              marginRight: '10px',
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: { xs: '20px', md: '25px' },
              padding: '6px 12px',
              border: '1px solid',
              borderColor: '#E5E5E5',
              color: 'black',
              borderRadius: '20px',
              textTransform: 'capitalize',
            }}
            onClick={navigateHome}
            className="mullish weight-700"
          >
            Home
          </Button>
          <Button
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: { xs: '20px', md: '25px' },
              // padding: "6px 12px",
              border: '1px solid',
              borderColor: '#E5E5E5',
              color: 'black',
              borderRadius: '20px',
              textTransform: 'capitalize',
              paddingLeft: '12px',
            }}
            className="mullish weight-700"
            disableRipple
            onClick={handleClick}
            id="basic-button"
            // aria-controls={open ? "basic-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? "true" : undefined}
          >
            Menu
            <ExpandMoreOutlinedIcon fontSize="small" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 1,
              sx: {
                borderRadius: '15px',
                backgroundColor: 'white',
                paddingX: '12px',
              },
            }}
          >
            {LoggedIn ? (
              <>
                {/* <MenuItem sx={MenuItemStyle} onClick={handleProfileModalOpen}> */}
                <MenuItem
                  sx={MenuItemStyle}
                  className="mullish weight-700"
                  onClick={() =>
                    navigateAndCloseDropdown(
                      userType === 'customer'
                        ? '/profile'
                        : '/consultant/updateprofilecons'
                    )
                  }
                >
                  Profile
                </MenuItem>
                <MenuItem
                  sx={MenuItemStyle}
                  className="mullish weight-700"
                  onClick={() =>
                    navigateAndCloseDropdown(
                      userType === 'customer'
                        ? '/sessionstatus'
                        : '/consultant/upcomingSessions'
                    )
                  }
                >
                  Upcoming Appointments
                </MenuItem>
                {/* <MenuItem className="mullish weight-700" sx={MenuItemStyle} onClick={handleClose}>
                  Membership
                </MenuItem> */}
                {/* <MenuItem className="mullish  sx={MenuItemStyle} onClick={handleClose}>
                  Payment methods
                </MenuItem>
                <MenuItem className="mullish  sx={MenuItemStyle} onClick={handleClose}>
                  Refer a Friend
                </MenuItem> */}
                {userType === 'customer' ? (
                  <MenuItem
                    sx={MenuItemStyle}
                    className="mullish weight-700"
                    onClick={() => navigateAndCloseDropdown('/report')}
                  >
                    Report a Problem
                  </MenuItem>
                ) : null}
              </>
            ) : null}
            <MenuItem
              sx={MenuItemStyle}
              className="mullish weight-700"
              onClick={() => navigateAndCloseDropdown('/help')}
            >
              Help & Support
            </MenuItem>
            {LoggedIn ? (
              <MenuItem
                sx={MenuItemStyle}
                onClick={handleLogout}
                className="mullish weight-700"
              >
                Logout
              </MenuItem>
            ) : (
              <MenuItem
                sx={MenuItemStyle}
                onClick={() => navigateAndCloseDropdown('/login')}
                className="mullish weight-700"
              >
                Login
              </MenuItem>
            )}
          </Menu>
        </Box>
        <IconButton
          onClick={toggleDrawer}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </Box>
  );
};

export default Header;
