// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const routes = {
    id: 'default',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/admin/',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'consultant',
            title: 'Consultant',
            type: 'item',
            url: '/admin/consultant',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'customer',
            title: 'Customer',
            type: 'item',
            url: '/admin/customer',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'language',
            title: 'Language',
            type: 'item',
            url: '/admin/language',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'session',
            title: 'Session',
            type: 'item',
            url: '/admin/session',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'demoSession',
            title: 'Demo Session',
            type: 'item',
            url: '/admin/demoSession',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'help',
            title: 'Help',
            type: 'item',
            url: '/admin/help',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'report',
            title: 'Report',
            type: 'item',
            url: '/admin/report',
            icon: icons.IconKey,
            breadcrumbs: false
        },
    ]
};

export default routes;
