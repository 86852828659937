import { Field, Form, Formik, ErrorMessage } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import {
  Button,
  Link,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  FormHelperText,
  FormControl,
  InputLabel,
  CircularProgress,
  Container,
  NativeSelect,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
} from '@mui/material';
import Loader from '../Components/Loader';
import UserAvtar from './../../../images/1.jpg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import Api from '../../../Api';
import Swal from 'sweetalert2';
import { get_countries, get_lang } from '../redux/master_data';
import { Person, Public } from '@mui/icons-material';
import Fade from '@mui/material/Fade';
import PaymentModal from './PaymentModal';
import { useRef } from 'react';

const BookSession = () => {
  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    language: '',
    consultant: {},
    date: '',
    country: '',
  });
  const [currentStep, setCurrentStep] = useState(0);

  const makeRequest = (values) => {
    setData(values);
    setTimeout(() => {
      setSuccess('Request sent successfully');
    }, 1000);
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
    if (currentStep === 2 && final) {
      makeRequest(data);
    }
  };
  const handleBackStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };
  const handleJumpStep = (step) => {
    setCurrentStep(step);
  };

  const steps = [
    <StepOne next={handleNextStep} data={data} setData={setData} />,
    <StepTwo
      next={handleNextStep}
      data={data}
      setData={setData}
      prev={handleBackStep}
      handleJumpStep={handleJumpStep}
    />,
    <StepThree
      next={handleNextStep}
      data={data}
      setData={setData}
      prev={handleBackStep}
      handleJumpStep={handleJumpStep}
    />,
  ];
  return <>{steps[currentStep]}</>;
};

const StepOne = (props) => {
  const dispatch = useDispatch();
  const [consultantList, setConsultantList] = useState([]);
  const [consultant, setConsultant] = useState(null);
  const [isProgress, setIsProgress] = useState(false);
  const [date, setDate] = useState('');
  const [langList, setLangList] = useState([]);
  const [countryId, setCountryId] = useState('');
  // const langList = useSelector((state) => {
  //   return get_lang(state, dispatch);
  // });

  const handleChangeLanguages = async (value, setFieldValue) => {
    setCountryId(value);
    if (props.data.consultant) {
      setConsultant(null);
    }
    setFieldValue('language', '');
    if (props.data.languages) {
      setLangList(null);
    }
    const res = await Api.get('/customer/language-list?country=' + value);
    setLangList(res.data.data);
  };

  const randomConsultant = (list) => {
    const consultantLength = list.length;
    const consultantNumber = Math.floor(Math.random() * consultantLength);

    if (consultantNumber >= 0) {
      const selectedConsultant = list[consultantNumber];
      if (
        consultantLength > 1 &&
        consultant &&
        selectedConsultant._id === consultant._id
      ) {
        randomConsultant(list);
      } else {
        setConsultant({ ...selectedConsultant });
        setDate('');
      }
    }
  };

  const show_image = (consultant) => {
    if (consultant.image) {
      return process.env.REACT_APP_S3_URL + consultant.image;
    } else {
      return '';
    }
  };

  const getConsultant1 = async (value) => {
    setIsProgress(true);
    let res;
    if (props.data.country) {
      res = await Api.get(
        '/customer/consultant-list?language=' +
          value +
          '&country=' +
          props.data.country
      );
      setConsultantList(res.data.data);
    } else {
      res = await Api.get(
        '/customer/consultant-list?language=' + value + '&country=' + countryId
      );
      setConsultantList(res.data.data);
    }

    setIsProgress(false);
    return res.data.data;
  };

  const getConsultant = async (value) => {
    const list = await getConsultant1(value);
    randomConsultant(list);
  };

  const handleSubmit = (values) => {
    setIsProgress(true);
    values['consultant'] = consultant;
    values['date'] = date;
    setIsProgress(false);
    props.next(values);
  };

  useEffect(() => {
    if (props.data.country) {
      setCountryId(props.data.country);
    }
    if (props.data.consultant && props.data.consultant._id) {
      getConsultant(props.data.language);
      setConsultant(props.data.consultant);
      setLangList(props.data.consultant.languages);
      // handleChangeLanguages(props.data.country);
    }
    if (props.data.date) {
      setDate(props.data.date);
    }
  }, []);

  const countries = useSelector((state) => {
    return get_countries(state, dispatch);
  });

  return (
    <Card
      className="book-action-inner"
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginTop: '40px',
        maxWidth: '900px',
        width: '95%',
        margin: '60px auto',
      }}
    >
      <Typography
        textAlign={'center'}
        component="h1"
        variant="h4"
        gutterBottom
        fontSize="36px"
        sx={{ fontWeight: '600' }}
      >
        Select a {langList.length > 0 ? 'language' : 'country'} for your
        session.
      </Typography>
      {/* <Typography textAlign={'center'} mb={4} variant="body1">
        Choose past Pro, or be paired with a new one fluent in your preferred
        language.
      </Typography> */}
      <CardContent
        sx={{
          backgroundColor: '#fff',
          padding: '40px',
          borderRadius: '30px',
          maxWidth: '900px',
          margin: '40px auto',
          textAlign: 'left',
        }}
      >
        <Formik
          initialValues={props.data}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            language: Yup.string().required('Required'),
            country: Yup.string().required('Required*'),
          })}
        >
          {(props) => {
            const {
              errors,
              handleChange,
              handleBlur,
              values,
              touched,
              setFieldValue,
            } = props;
            console.log('values', values.language);
            return (
              <Form>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel required id="country">
                    Country
                  </InputLabel>
                  <Field
                    name="country"
                    id="country"
                    label="country"
                    as={NativeSelect}
                    input={<OutlinedInput name="country" />}
                    margin="normal"
                    fullWidth={true}
                    required
                    displayEmpty
                    onChange={(e) => {
                      handleChangeLanguages(e.target.value, setFieldValue);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.country}
                    error={errors.country && touched.country}
                    startAdornment={
                      <InputAdornment position="start">
                        <PublicIcon />
                      </InputAdornment>
                    }
                  >
                    <option value={''} disabled>
                      Choose Country
                    </option>
                    {countries.map((country) => {
                      return (
                        <option value={country._id}>{country.name}</option>
                      );
                    })}
                  </Field>
                  <FormHelperText error={true} sx={{ ml: '8px' }}>
                    {touched.country && errors.country}
                  </FormHelperText>
                </FormControl>
                {langList.length > 0 ? (
                  <Fade in={true}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel required id="language">
                        Language
                      </InputLabel>
                      <Field
                        name="language"
                        id="language"
                        label="Language"
                        as={NativeSelect}
                        input={<OutlinedInput name="language" />}
                        margin="normal"
                        fullWidth={true}
                        required
                        displayEmpty
                        onChange={(e) => [
                          setFieldValue('language', e.target.value),
                          getConsultant(e.target.value),
                          (e) => handleChange(e),
                        ]}
                        onBlur={handleBlur}
                        value={values.language}
                        error={errors.language && touched.language}
                        helperText={
                          touched.language &&
                          errors.language &&
                          touched.language
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <LanguageIcon />
                          </InputAdornment>
                        }
                      >
                        <option value={''} disabled>
                          Choose Language
                        </option>
                        {langList.map((language) => {
                          return (
                            <option value={language.langName}>
                              {language.langName || language}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="language"
                        render={(msg) => (
                          <FormHelperText error={true}>{msg}</FormHelperText>
                        )}
                      />
                    </FormControl>
                  </Fade>
                ) : null}

                {consultant && langList.length > 0 && (
                  <>
                    <Box
                      sx={{
                        backgroundColor: '#18253A',
                        borderRadius: '4px',
                        padding: '80px',
                        paddingBottom: '30px',
                        textAlign: 'center',
                        position: 'relative',
                        marginTop: '50px',
                      }}
                    >
                      {consultant.image ? (
                        <Box
                          className="user-avatar"
                          component="img"
                          sx={{
                            height: 90,
                            width: 90,
                            maxHeight: { xs: 60, md: 90 },
                            maxWidth: { xs: 60, md: 90 },
                          }}
                          alt={consultant.image ? '' : consultant.image.name}
                          src={show_image(consultant)}
                        />
                      ) : (
                        <Box
                          className="user-avatar"
                          sx={{
                            height: 90,
                            width: 90,
                            lineHeight: '6',
                            maxHeight: { xs: 60, md: 90 },
                            maxWidth: { xs: 60, md: 90 },
                            background: '#fff',
                            fontSize: 'large',
                          }}
                        >
                          <Person fontSize="large" />
                        </Box>
                      )}
                      <Typography component="h3" varient="h6" color="#ffffff">
                        Your Pro: {consultant.firstName}
                      </Typography>
                    </Box>

                    <Button
                      component="label"
                      fullWidth="true"
                      size="large"
                      onClick={() => randomConsultant(consultantList)}
                      sx={{
                        mt: 4,
                        backgroundColor: '#E8EEF7',
                        color: '#313131',
                        textTransform: 'capitalize',
                      }}
                    >
                      Talk to a different Pro
                    </Button>

                    <Box textAlign={'right'}>
                      <Button
                        type="submit"
                        disabled={
                          isProgress ||
                          consultant.length === 0 ||
                          consultantList.length === 0
                        }
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, backgroundColor: '#5e17eb' }}
                      >
                        {!isProgress && 'Next'}
                        {isProgress && <CircularProgress />}
                      </Button>
                    </Box>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

const StepTwo = (props) => {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 300);

  const handleSubmit = (values) => {
    props.next(values);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chooseDate, setChooseDate] = useState(new Date());
  const todayDate = useMemo(() => {
    return moment().startOf('date').toDate();
  });

  const [calender, setCalender] = useState([]);
  const [availability, setAvailability] = useState({
    morning: [],
    evening: [],
    afternoon: [],
  });
  const [oldSessions, setOldSessions] = useState({});

  const getWeek = (date) => {
    var weekDays = moment(date).startOf('week');

    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(weekDays.toDate());
      weekDays.add(1, 'days');
    }
    setCalender([...week]);
  };

  const nextWeek = () => {
    const selectedDate2 = moment(selectedDate).add(7, 'days').toDate();
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  };

  const prevWeek = () => {
    const selectedDate2 = moment(selectedDate).add(-7, 'days').toDate();
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  };

  const clickDate = async (date) => {
    setChooseDate(date);

    const startTime = new Date(date);
    startTime.setHours(0, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(23, 59, 59);

    const res = await Api.post('/customer/consultant-availability', {
      consultantId: props.data.consultant._id,
      startTime: startTime,
      endTime: endTime,
    });
    let times = {
      morning: [],
      evening: [],
      afternoon: [],
    };

    const morningStart = new Date(date);
    morningStart.setHours(8, 0, 0);
    const morningEnd = new Date(date);
    morningEnd.setHours(12, 0, 0);

    const afternoonStart = new Date(date);
    afternoonStart.setHours(12, 0, 0);
    const afternoonEnd = new Date(date);
    afternoonEnd.setHours(16, 0, 0);

    const eveningStart = new Date(date);
    eveningStart.setHours(16, 0, 0);
    const eveningEnd = new Date(date);
    eveningEnd.setHours(20, 0, 0);

    const oldSessionsData = {};

    for (let i = 0; i < 24; i++) {
      for (const row of res.data.data) {
        const startTimeMoment = moment(startTime).add(i, 'hours');
        const endTimeMoment = moment(startTime).add(i + 1, 'hours');

        let startDiff = startTimeMoment.diff(
          new Date(row.startTime),
          'seconds'
        );
        let endDiff = endTimeMoment.diff(new Date(row.endTime), 'seconds');

        if (startDiff >= 0 && endDiff <= 0) {
          if (
            startTimeMoment.toDate() >= morningStart &&
            endTimeMoment.toDate() <= morningEnd
          ) {
            times.morning.push(startTimeMoment.toDate());
          } else if (
            startTimeMoment.toDate() >= afternoonStart &&
            endTimeMoment.toDate() <= afternoonEnd
          ) {
            times.afternoon.push(startTimeMoment.toDate());
          } else if (
            startTimeMoment.toDate() >= eveningStart &&
            endTimeMoment.toDate() <= eveningEnd
          ) {
            times.evening.push(startTimeMoment.toDate());
          }
        }
      }

      if (res.data.sessions) {
        for (const row of res.data.sessions) {
          const startTimeMoment = moment(startTime).add(i, 'hours');
          const endTimeMoment = moment(startTime).add(i + 1, 'hours');

          let startDiff = startTimeMoment.diff(
            new Date(row.dateTime),
            'seconds'
          );
          let endDiff = endTimeMoment.diff(new Date(row.dateTime), 'seconds');

          if (startDiff <= 0 && endDiff > 0) {
            const formatTime = startTimeMoment.format('h:mm a');
            oldSessionsData[formatTime] = true;
          }
        }
      }
    }

    setAvailability(times);
    setOldSessions(oldSessionsData);
  };
  const handleJumpStep = (step) => {
    props.handleJumpStep(step);
  };

  const showWeekMonth = () => {
    var weekDays = moment(selectedDate).startOf('week').toDate();
    const lastDateOfMonth = moment(weekDays).add(6, 'days').toDate();
    if (weekDays.getMonth() == lastDateOfMonth.getMonth()) {
      return (
        moment(weekDays).format('MMMM') +
        '  ' +
        moment(selectedDate).format('Y')
      );
    } else {
      return (
        moment(weekDays).format('MMM') +
        ' - ' +
        moment(lastDateOfMonth).format('MMM') +
        '  ' +
        moment(selectedDate).format('Y')
      );
    }
  };

  const disableDateBefore = (item) => {
    let customDate = new Date(item).setMinutes(
      new Date(item).getMinutes() - 20
    );

    if (oldSessions[moment(item).format('h:mm a')]) {
      return true;
    } else if (new Date() > new Date(customDate)) {
      console.log('20 minutes');
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.data && props.data.date) {
      setSelectedDate(props.data.date);
      getWeek(props.data.date);
      clickDate(props.data.date);
    } else {
      getWeek(selectedDate);
      clickDate(new Date());
    }
  }, []);

  return (
    <>
      {loading ? (
        <Card
          className="book-action-inner"
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            marginTop: '40px',
            maxWidth: '900px',
            width: '95%',
            margin: '60px auto',
          }}
        >
          <Typography
            textAlign={'center'}
            component="h1"
            variant="h4"
            gutterBottom
            fontSize="36px"
            sx={{ fontWeight: '600' }}
          >
            Pick a date and time
          </Typography>
          <Typography textAlign={'center'} variant="body1">
            Session is scheduled for 30 minutes, but can be as short as you’d
            like.
          </Typography>
          <CardContent
            sx={{
              backgroundColor: '#fff',
              padding: '40px',
              borderRadius: '30px',
              maxWidth: '900px',
              margin: '40px auto',
              textAlign: 'left',
            }}
          >
            <Loader />
            <Typography variant="body1" textAlign="center">
              <strong>Don’t see good time?</strong>{' '}
              <Link
                href="#"
                onClick={() => handleJumpStep(0)}
                sx={{ color: '#5e17eb', textDecoration: 'none' }}
              >
                Edit your preferences
              </Link>{' '}
              to see other availability.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Card
          className="book-action-inner"
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            marginTop: '40px',
            maxWidth: '900px',
            width: '95%',
            margin: '60px auto',
          }}
        >
          <Typography
            textAlign={'center'}
            component="h1"
            variant="h4"
            gutterBottom
            fontSize="36px"
            sx={{ fontWeight: '600' }}
          >
            Pick a date and time
          </Typography>
          <Typography textAlign={'center'} variant="body1">
            Session is scheduled for 30 minutes, but can be as short as you’d
            like.
          </Typography>
          <CardContent
            sx={{
              backgroundColor: '#fff',
              padding: '40px',
              borderRadius: '30px',
              maxWidth: '900px',
              margin: '40px auto',
              textAlign: 'left',
            }}
          >
            <Formik
              initialValues={props.data}
              onSubmit={async (values, { resetForm }) => {
                handleSubmit(values);
              }}
            >
              {(props) => {
                const {
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                  touched,
                } = props;
                return (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      item
                      textAlign="center"
                      mb={2}
                      xs={12}
                      sx={{ border: '1px solid #9F9F9F', borderRadius: '5px' }}
                    >
                      <CalendarMonthIcon
                        sx={{ margin: '10px' }}
                        fontSize="large"
                      />
                      <Box mb={2}>{showWeekMonth()}</Box>
                      <Box mb={3}>
                        <Button
                          type="button"
                          onClick={prevWeek}
                          disabled={new Date() > selectedDate}
                        >
                          <ArrowBackIosNewIcon />
                        </Button>
                        {calender.map((date, i) => (
                          <Button
                            name="date"
                            type="button"
                            key={i}
                            onChange={handleChange}
                            onClick={() => [
                              clickDate(date),
                              setFieldValue('date', ''),
                            ]}
                            disabled={todayDate > date}
                            variant={
                              moment(chooseDate).format('MM-D-YYYY') ==
                              moment(date).format('MM-D-YYYY')
                                ? 'contained'
                                : 'outlined'
                            }
                            onBlur={handleBlur}
                            value={values.date}
                            error={errors.date && touched.date && errors.date}
                            touched={true}
                            helperText={
                              errors.date && touched.date && errors.date
                            }
                          >
                            {moment(date).format('ddd')} <br />
                            {moment(date).format('D')}
                          </Button>
                        ))}
                        <Button type="button" onClick={nextWeek}>
                          <ArrowForwardIosIcon />
                        </Button>
                      </Box>
                    </Grid>

                    <Grid
                      container
                      spacing={0}
                      sx={{ border: '1px solid #9F9F9F', borderRadius: '5px' }}
                      mb={5}
                    >
                      <Grid item xs={12} sm={4} p={2}>
                        <Typography
                          variant="h6"
                          textAlign={'center'}
                          component="h6"
                        >
                          Morning
                        </Typography>

                        {availability['morning'].length > 0 && (
                          <>
                            {availability['morning'].map((item) => {
                              return (
                                <Button
                                  sx={{ cursor: 'pointer', marginTop: '5px' }}
                                  disabled={disableDateBefore(item)}
                                  fullWidth
                                  onClick={() => setFieldValue('date', item)}
                                  variant={
                                    moment(values.date).format(
                                      'MM-D-YYYY h:mm a'
                                    ) == moment(item).format('MM-D-YYYY h:mm a')
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                >
                                  {moment(item).format('h:mm a')}
                                </Button>
                              );
                            })}
                          </>
                        )}
                        {!availability['morning'].length && (
                          <Box textAlign={'center'}>No sessions available</Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4} p={2}>
                        <Typography
                          variant="h6"
                          fullWidth
                          component="h6"
                          textAlign={'center'}
                          sx={{ textAlign: 'center' }}
                        >
                          Afternoon
                        </Typography>
                        {availability['afternoon'].length > 0 && (
                          <Box textAlign={'center'}>
                            {availability['afternoon'].map((item) => {
                              return (
                                <Button
                                  disabled={disableDateBefore(item)}
                                  sx={{ cursor: 'pointer', marginTop: '5px' }}
                                  fullWidth
                                  onClick={() => setFieldValue('date', item)}
                                  variant={
                                    moment(values.date).format(
                                      'MM-D-YYYY h:mm a'
                                    ) == moment(item).format('MM-D-YYYY h:mm a')
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                >
                                  {moment(item).format('h:mm a')}
                                </Button>
                              );
                            })}
                          </Box>
                        )}
                        {!availability['afternoon'].length && (
                          <Box textAlign={'center'}>No sessions available</Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4} p={2}>
                        <Typography
                          variant="h6"
                          textAlign={'center'}
                          component="h6"
                        >
                          Evening
                        </Typography>
                        {availability['evening'].length > 0 && (
                          <>
                            {availability['evening'].map((item) => {
                              return (
                                <Button
                                  sx={{ cursor: 'pointer', marginTop: '5px' }}
                                  fullWidth
                                  disabled={disableDateBefore(item)}
                                  onClick={() => setFieldValue('date', item)}
                                  variant={
                                    moment(values.date).format(
                                      'MM-D-YYYY h:mm a'
                                    ) == moment(item).format('MM-D-YYYY h:mm a')
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                >
                                  {moment(item).format('h:mm a')}
                                </Button>
                              );
                            })}
                          </>
                        )}
                        {!availability['evening'].length && (
                          <Box textAlign={'center'}>No sessions available</Box>
                        )}
                      </Grid>
                    </Grid>
                    <ErrorMessage
                      name="date"
                      render={(msg) => (
                        <FormHelperText error={true}>{msg}</FormHelperText>
                      )}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        mt: 3,
                        mb: 5,
                        backgroundColor: '#5e17eb',
                      }}
                      onClick={() => handleJumpStep(0)}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        mt: 3,
                        mb: 5,
                        backgroundColor: '#5e17eb',
                        float: 'right',
                      }}
                      disabled={values.date ? false : true}
                    >
                      Next
                    </Button>
                  </Form>
                );
              }}
            </Formik>

            <Typography textAlign={'center'} variant="body1">
              <strong>Don’t see good time?</strong>{' '}
              <Link
                href="#"
                onClick={() => handleJumpStep(0)}
                sx={{ color: '#5e17eb', textDecoration: 'none' }}
              >
                Edit your preferences
              </Link>{' '}
              to see other availability.
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

const StepThree = (props) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [error, setError] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const subscriptionStatus = async () => {
    const response = await Api.get('/customer/subscription');
    setSubscriptionData(response?.data?.subscription);
  };

  useEffect(() => {
    subscriptionStatus();
  }, []);

  const handleSubmit = async (values) => {
    setSuccess('');
    setError('');
    const slotDateFormatted = moment(values.date).format('ddd, MMM D');
    const slotTimeFormatted = moment(values.date).format('hh:mm A');

    const saveData = {
      consultantId: values.consultant._id,
      language: values.language,
      dateTime: values.date,
      slotDateFormatted: slotDateFormatted,
      slotTimeFormatted: slotTimeFormatted,
    };
    if (
      subscriptionData?.remainingSession === 0 ||
      subscriptionData?.subscription_type === 'single' ||
      subscriptionData === null
    ) {
      dispatch({ type: 'SHOW_MODAL' });
    } else {
      setIsProgress(true);
      const response = await Api.post('/customer/session', saveData);
      if (response.data.statusCode === 200) {
        Swal.fire({
          title: 'Success',
          text: 'Session booked successfully!!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(function () {
          window.location.href = '/home';
        });
        setIsProgress(false);
      } else {
        Swal.fire({
          title: 'Error',
          text: response.data.errorMsg,
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(function () {});
        setIsProgress(false);
      }
    }
  };

  const handleJumpStep = (step) => {
    props.handleJumpStep(step);
  };

  const bookingVerify = async () => {
    // send booking request here
    console.log('booking verify works');
    const slotDateFormatted = moment(formRef.current.values.date).format(
      'ddd, MMM D'
    );
    const slotTimeFormatted = moment(formRef.current.values.date).format(
      'hh:mm A'
    );
    const saveData = {
      consultantId: formRef.current.values.consultant._id,
      language: formRef.current.values.language,
      dateTime: formRef.current.values.date,
      slotDateFormatted: slotDateFormatted,
      slotTimeFormatted: slotTimeFormatted,
    };
    const response = await Api.post('/customer/session', saveData);
    if (response.data.statusCode === 200) {
      Swal.fire({
        title: 'Success',
        text: 'Session booked successfully!!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(function () {
        window.location.href = '/home';
      });
      setIsProgress(false);
    } else {
      Swal.fire({
        title: 'Error',
        text: response.data.errorMsg,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(function () {});
    }
  };

  return (
    <Box>
      <PaymentModal bookingVerify={bookingVerify} fromBooking={true} />
      <Card
        className="book-action-inner"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          maxWidth: '900px',
          width: '95%',
          margin: '60px auto',
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          gutterBottom
          fontSize="36px"
          sx={{ fontWeight: '600' }}
          textAlign={'center'}
          mt={5}
        >
          Review your Session Details
        </Typography>
        <CardContent
          className=" pick-date-time"
          sx={{
            backgroundColor: '#fff',
            padding: '40px',
            borderRadius: '30px',
            maxWidth: '900px',
            margin: '30px auto',
            textAlign: 'left',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#18253A',
              borderRadius: '4px',
              padding: '80px',
              paddingBottom: '30px',
              textAlign: 'center',
              position: 'relative',
              marginTop: '50px',
              marginBottom: '50px',
            }}
          >
            <Box
              className="user-avatar"
              component="img"
              sx={{
                height: 90,
                width: 90,
                maxHeight: { xs: 60, md: 90 },
                maxWidth: { xs: 60, md: 90 },
              }}
              alt="User Avatar"
              src={UserAvtar}
            />
            <Typography component="h3" varient="h6" color="#ffffff">
              We've found you a Pro! {props.data.consultant.firstName}
            </Typography>
          </Box>
          <Formik
            initialValues={props.data}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values, handleChange, handleSubmit, errors, props }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} width="100%" ml="0px">
                  <Grid item xs={6} sx={{ pl: '24px' }}>
                    <Typography component="h3" varient="h6">
                      {moment(values.date).format('ddd, MMM D')} at{' '}
                      {moment(values.date).format('hh:mm A')}
                    </Typography>
                    <Button
                      sx={{ padding: '0', justifyContent: 'left' }}
                      variant="text"
                      onClick={() => handleJumpStep(1)}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Container sx={{ float: 'right', width: 'unset' }}>
                      <Typography component="h3" varient="h6">
                        Language: {values.language}
                      </Typography>
                      <Button
                        sx={{
                          padding: '0',
                          float: 'right',
                          width: 'unset',
                          'min-width': 'unset',
                        }}
                        variant="text"
                        onClick={() => handleJumpStep(0)}
                      >
                        Edit
                      </Button>
                    </Container>
                  </Grid>
                </Grid>

                {!success && (
                  <Button
                    component="label"
                    fullWidth="true"
                    size="large"
                    sx={{
                      mt: 4,
                      mb: 1,
                      backgroundColor: '#E8EEF7',
                      color: '#313131',
                      textTransform: 'capitalize',
                    }}
                    onClick={handleSubmit}
                    disabled={isProgress}
                  >
                    {!isProgress && 'Confirm & Book'}
                    {isProgress && <CircularProgress />}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BookSession;
