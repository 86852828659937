import React,{useState, useEffect, useMemo} from 'react'
import moment from 'moment';

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chooseDate, setChooseDate] = useState(new Date());
  const todayDate = useMemo(() => {
    return moment().startOf('date').toDate();
  });
  
  const[calender, setCalender] = useState([]);

  const getWeek = (date) => {
    var weekDays = moment(date).startOf('week');

    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(weekDays.toDate());
      weekDays.add(1,'days');
    }
    setCalender([...week]);
  }

  const nextWeek = () => {
    const selectedDate2 = moment(selectedDate).add(7,'days').toDate();
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  }

  const prevWeek = () => {
    const selectedDate2 = moment(selectedDate).add(-7,'days').toDate();
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  }

  const clickDate = (date) => {
    setChooseDate(date);
    // console.log(date);
  }

  useEffect(() => {
    getWeek(selectedDate);
  }, []);

  return (
    <div>
      Weekly Calendar
      <div>{moment(chooseDate).format("dddd, MMMM D")}</div>
      <div>
        <button onClick={prevWeek} disabled={new Date() > selectedDate}>Prev</button>
        {calender.map((date, i) => (
          <button key={i} onClick={() => clickDate(date)} disabled={todayDate > date} className={moment(chooseDate).format("MM-D-YYYY") == moment(date).format("MM-D-YYYY")?"btn btn-primary":""}>
            {moment(date).format("ddd")} <br />
            {moment(date).format("D")}
          </button>
        ))}
        <button onClick={nextWeek}>Next</button>
      </div>
    </div>
  )
}

export default Calendar