import React,{useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import { Button, Grid, Box, Typography, Rating, Container, Modal } from '@mui/material';
import userfeedback from '../../../images/userfeedback.svg'
import Api from '../../../Api';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#E7EB20',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


function UserFeedback() {
    const [feedData, setFeedData] = useState([])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    
    
    const handleClose = () => setOpen(false);

    const [perFeedback, setPerFeedback] = useState()
    console.log(perFeedback,'perFeedback')

    const feedbackDetails = async()=>{
        const response = await Api.get("/consultant/customer-feedback");
        setFeedData(response.data.data.docs)
        return [...response.data.data.docs]
    }

    useEffect(()=>{
        feedbackDetails()
    },[])

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px' }}>
            <Grid container columnSpacing={{ xs: 0, md: 2 }}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h1" fontSize={"36px"} gutterBottom fontWeight={"600"}>
                        Customer's Feedback
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        className=''
                        component="img"
                        sx={{

                        }}
                        alt="Home banner img"
                        src={userfeedback}
                    />
                </Grid>
            </Grid>
            <Box sx={{ backgroundColor: "#ffffff", marginBottom: '50px', borderRadius: '32px', padding: '50px' }}>
                {feedData.length > 0 ?
                    <>
                        {feedData?.map(item => {
                            return (
                                    <Grid container  spacing={2} backgroundColor={"#F7F5F5"} borderRadius={2} mb={2}>
                                        <Grid ml={2} item xs={6} md={5.5} sx={{ borderRight: 1, borderColor: '#D2D2D2', textTransform:'capitalize' }} >
                                            <Typography variant="h2" fontSize={"16px"} color='#5E17EB' gutterBottom>{item.customerName}</Typography>
                                            <Typography variant="h4" fontSize={"14px"}  color='#6F6F6F' gutterBottom>{item.countryName}</Typography>
                                            <Typography variant="h4" fontSize={"12px"} color='#3D3D3E' gutterBottom>{item.tags}</Typography>
                                        </Grid>
                                        <Grid ml={2} item xs={6} md={4}>
                                            <Typography variant="h4" fontSize={"14px"} color='#000000' gutterBottom>Review</Typography>
                                            <StyledRating name="read-only" mb={2} value={item.ratings} readOnly />
                                            <Button
                                                variant="outlined"
                                                sx={{ backgroundColor: "#5E17EB", color: "#ffffff", marginBottom: "5px", display:'block' }}
                                                onClick={() => [setPerFeedback(item), handleOpen()]}
                                            >
                                                Review sheet
                                            </Button>
                                        </Grid>
                                    </Grid>

                            )
                        })
                        }
                    </>:<Typography variant="h4" fontSize={"20px"} color='#3D3D3E' sx={{textAlign:'center'}} gutterBottom>No Feedback to show</Typography>
                }
            </Box>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={1}>
                        Feedback Question/Response
                    </Typography>
                    {perFeedback &&
                        <Typography
                        key={perFeedback._id}
                        value={perFeedback._id}
                        id="modal-modal-description"
                        sx={{textTransform:'capitalize'}}
                        >
                        <Typography>About my situation : {perFeedback.about_situation}</Typography>
                        <Typography>Address the situation : {perFeedback.address_situation}</Typography>
                        <Typography>City Name : {perFeedback.cityName}</Typography>
                        <Typography>Country Name : {perFeedback.countryName}</Typography>
                        <Typography>Customer Name : {perFeedback.customerName}</Typography>
                        
                        <Typography>Progress : {perFeedback.progress==='sdisagree'?<>Strongly Disagree</>
                                                :perFeedback.progress==='disagree'?<>Disagree</>
                                                :perFeedback.progress==='neutral'?<>Neutral</>
                                                :perFeedback.progress==='agree'?<>Agree</>
                                                :perFeedback.progress==='sagree'?<>Strongly Agree</>
                                                :''
                                               }
                        </Typography>
                        <Typography>Skill and strategy : {perFeedback.skill_strategy}</Typography>
                        <Typography>Tags : {perFeedback.tags}</Typography>
                        </Typography>
                    } 
                    </Box>
                </Modal>
            </div>

        </Container>
    )
}

export default UserFeedback